import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        marginTop: "3rem",
        marginBottom: "5rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    image: {
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        width: '40rem',
        [theme.breakpoints.down('sm')]: {
            height: '20rem'
        }
    },
    descContainer: {
        maxWidth: '45rem',
    },
    title: {
        marginTop: "1em"
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    }
}));

const HandInHand = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="column">
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid item style={{ marginRight: matchesSM ? 0 : "5em" }}>
                            <img
                                className={classes.image}
                                alt="Hand in Hand pic"
                                src={require('../../assets/eysc-hand-in-hand-soccer-sm.jpg')}
                            />
                        </Grid>
                        <Grid
                            style={{
                                textAlign: matchesSM ? "center" : undefined
                            }}
                            className={classes.descContainer}
                        >
                            <Typography variant="h4" className={classes.title}>{t('handNhand.title')}</Typography>
                            <Typography variant="body1">
                                <br/>
                                {t('handNhand.text1')}
                                <a className={classes.link}
                                   target="_blank"
                                   rel='noreferrer noopener'
                                   href="https://handinhandkids.org/">
                                    {t('handNhand.title')}</a>
                                {t('handNhand.text2')}<br/><br/>
                                {t('handNhand.text3')}<br/><br/>
                                {t('handNhand.text4')}<br/><br/>
                                {t('handNhand.text5')}
                                <a className={classes.link}
                                   target="_blank"
                                   rel='noreferrer noopener'
                                   href="https://handinhandkids.org/outreach-program/">
                                    {t('handNhand.link1')}</a><br/><br/>
                                {t('handNhand.text6')}<br/><br/>
                                {t('handNhand.text7')}<br/><br/>
                                {t('handNhand.text8')}<br/><br/>
                                {t('handNhand.text9')}<br/><br/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default HandInHand;