import { Link } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { ListProps, Routes } from './Navigation.model';

const useStyles = makeStyles((theme:Theme) => ({
    drawerItem: {
        ...theme.typography.tab,
        color: 'white',
        opacity: 0.7,
        fontWeight: 600,
        '&:hover': {
            opacity: 1,
            color: theme.palette.secondary.main,
        }
    },
    drawerItemRegister: {
        backgroundColor: theme.palette.secondary.main,
    },
    drawerItemSelected: {
        "& .MuiListItemText-root": {
            opacity: 1,
            fontWeight: 600,
            color: theme.palette.secondary.main,
        },
        ...theme.typography.tab,
    },
    expansion: {
        backgroundColor: theme.palette.primary.main,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        "&.Mui-expanded": {
            margin: 0,
            borderBottom: 0
        },
        "&::before": {
            backgroundColor: "rgba(0, 0, 0, 0)"
        }
    },
    expansionDetails: {
        padding: 0,
        opacity: 0.7,
        marginLeft: '1rem',
    },
    expansionSummary: {
        padding: "0 24px 0 16px",
    }
}));

const NavListItem = (props: ListProps) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <List disablePadding key={`${Math.floor(Math.random() * 1000)}`}>
                {props.routes.map((route: Routes, index: number) =>
                    route.subRoutes ? (
                        <Accordion
                            elevation={0}
                            key={`${route.name} ${Math.floor(Math.random() * 1000)}`}
                            classes={{ root: classes.expansion }}
                            defaultExpanded={index === props.tabIndex}
                        >
                            <AccordionSummary
                                key={`${route.name} ${Math.floor(Math.random() * 1000)}`}
                                classes={{ root: classes.expansionSummary }}
                                expandIcon={<ExpandMoreIcon color="secondary" />}
                            >
                                <ListItemText
                                    key={`${route.name} ${Math.floor(Math.random() * 1000)}`}
                                    className={classes.drawerItem}
                                    disableTypography
                                    style={{ opacity: props.tabIndex === index ? index : undefined }}
                                >
                                    {route.name}
                                </ListItemText>
                            </AccordionSummary>
                            <AccordionDetails
                                classes={{ root: classes.expansionDetails }}
                            >
                                <Grid container direction="column" key={`${route.name} ${Math.floor(Math.random() * 1000)}`}>
                                    {route.subRoutes.map((route:any, index: number) => ( //iterate thru sub menu
                                        <Grid item key={`${route.name} ${Math.floor(Math.random() * 1000)}`}>
                                            <ListItem
                                                divider
                                                key={`${route.name} ${Math.floor(Math.random() * 1000)}`}
                                                button
                                                component={Link}
                                                to={route.link ? route.link : route.href ? route.href: ''}
                                                selected={
                                                    props.tabIndex === route.tabIndex &&
                                                    route.subTabIndex === props.tabSubIndex //&&
                                                    // window.location.pathname !== route.link
                                                }
                                                classes={{ selected: classes.drawerItemSelected }}
                                                onClick={() => {
                                                    props.setOpenDrawer(false);
                                                    props.setTabIndex(route.tabIndex);
                                                    props.setTabIndex(route.tabIndex);
                                                    props.setTabSubIndex(route.subTabIndex);
                                                    route.setSelectedIndex? route.setSelectedIndex(route.subTabIndex) : props.setTabIndex(route.tabIndex);
                                                }}
                                            >
                                                <ListItemText
                                                    key={`${route.name} ${Math.floor(Math.random() * 1000)}`}
                                                    className={classes.drawerItem}
                                                    disableTypography
                                                >
                                                    {route.name}
                                                </ListItemText>
                                            </ListItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <ListItem
                            divider
                            key={`${route.name} ${Math.floor(Math.random() * 1000)}`}
                            button
                            component={Link}
                            to={route.link ? route.link : route.href ? route.href: ''}
                            selected={props.tabIndex === route.tabIndex}
                            classes={{ selected: classes.drawerItemSelected }}
                            onClick={() => {
                                props.setOpenDrawer(false);
                                props.setTabIndex(route.tabIndex);
                            }}
                        >
                            <ListItemText
                                key={`${route.name} ${Math.floor(Math.random() * 1000)}`}
                                className={classes.drawerItem} disableTypography>
                                {route.name}
                            </ListItemText>
                        </ListItem>
                    )
                )}
            </List>
        </React.Fragment>
    );
};

export default NavListItem;