import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme:Theme) => ({
    container: {
        width: 'unset',
        marginLeft: 'auto',
    },
    button: {
        ...theme.typography.tab,
        minWidth: 10,
        opacity: 0.7,
        paddingRight: 0,
        textTransform: 'uppercase',
        [theme.breakpoints.down('lg')]: {
            marginLeft: 0,
            paddingLeft: 0,
        },
    },
    arrow: {
        opacity: 0.7,
        paddingLeft: '2px'
    },
    menu: {
        backgroundColor: '#010736',
        color: 'white',
    },
    menuItem: {
        opacity: 0.7,
    }
}));

export default function LanguageSelector() {
    const { i18n } = useTranslation();
    const options = ['English', 'Arabic | عربي', 'Korean | 한국인', 'Russian | Русский', 'Spanish | Español', 'Ukrainian | Українська', 'Vietnamese | Tiếng Việt'];
    const optionsTranslation = ['English', 'عربي', '한국인', 'Русский', 'Español', 'Українська', 'Tiếng Việt'];
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const classes = useStyles();

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);

        //Change language to selected language
        switch (index){
            case 1:
                i18n.changeLanguage('ar');
                break;
            case 2:
                i18n.changeLanguage('kr');
                break;
            case 3:
                i18n.changeLanguage('ru');
                break;
            case 4:
                i18n.changeLanguage('es');
                break;
            case 5:
                i18n.changeLanguage('ua');
                break;
            case 6:
                i18n.changeLanguage('vn');
                break;
            default:
                i18n.changeLanguage('en');
                break;
        }
    };

    useEffect(() => {
        //Ensure correct language shows on refresh
        switch (i18n.language) {
            case 'ar':
                setSelectedIndex(1);
                break;
            case 'kr':
                setSelectedIndex(2);
                break;
            case 'ru':
                setSelectedIndex(3);
                break;
            case 'es':
                setSelectedIndex(4);
                break;
            case 'ua':
                setSelectedIndex(5);
                break;
            case 'vn':
                setSelectedIndex(6);
                break;
            default:
                setSelectedIndex(0);
        }
    },[selectedIndex, i18n.language]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="flex-end" className={classes.container}>
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" >
                    <Button onClick={handleToggle} className={classes.button}>
                        {optionsTranslation[selectedIndex]}
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="Change Language"
                        title='Change language'
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        className={classes.arrow}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" className={classes.menu}>
                                        {options.map((option, index) => (
                                            <MenuItem
                                                className={classes.menuItem}
                                                key={option}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}