import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import {Theme, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import {Sponsor, SponsorsList} from "./Sponsors.model";
import SponsorsCard from "./SponsorsCard";

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        marginTop: "3rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    grid: {
        padding: '2rem',
        [theme.breakpoints.down("sm")]: {
            padding: '2rem 0',
        }
    },
    image: {
        width: '30rem',
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        [theme.breakpoints.down('sm')]: {
            width: '23rem'
        }
    },
    descContainer: {
        maxWidth: '40rem',
    },
    subtitle: {
        lineHeight: "1.5rem"
    },
    card: {
        maxWidth: '360px',
        height: '100%'
    },
    title: {
        padding: "2rem 0 0 5 rem",
    },
    GridSubTitle: {
        backgroundColor: theme.palette.secondary.light,
        padding: '0.5rem'
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
}));

const Sponsors = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="center"
                className={classes.gridContainer}
                style={{marginTop:'5rem'}}
            >
                <Grid item style={{ paddingRight: matchesSM ? 0 : "5em" }}>
                    <img
                        className={classes.image}
                        alt="Sponsors pic"
                        src={require('../../assets/sponsor.jpeg')}
                    />
                </Grid>
                <Grid
                    item
                    style={{
                        textAlign: matchesSM ? "center" : undefined
                    }}
                    className={classes.descContainer}
                >
                    <Typography variant="h4" className={classes.title}>{t('sponsors.title')}</Typography>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                        <b>{t('sponsors.subtitle')}</b><br/><br/>
                        {t('sponsors.text1')}<br/><br/>
                        {t('sponsors.text2')}<br/><br/>
                        {t('sponsors.text3')}
                        <a className={classes.link} href="mailto:sponsorship@EYSC.club">sponsorship@EYSC.club</a><br/><br/>
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.grid}
                spacing={2}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    className={classes.grid}
                    spacing={2}
                >
                    {SponsorsList.map((sponsor: Sponsor) => (
                        <SponsorsCard
                            key={sponsor.name + Math.floor(Math.random() * 1000)}
                            name={sponsor.name}
                            imagePath={sponsor.imagePath}
                            url={sponsor.url}
                        />
                    ))}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Sponsors;