import React, {useEffect} from 'react';
import { Ages, Director, Directors, Genders, Team, Teams, Trainer, Trainers, Types } from './Coaches.model';
import CoachesCard from './CoachesCard';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { MenuItem, TextField } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DirectorsCard from './DirectorsCard';
import TrainersCard from './TraininersCard';

const useStyles = makeStyles((theme:Theme) => ({
    grid: {
        padding: '2rem',
        [theme.breakpoints.down("sm")]: {
            padding: '2rem 0',
        }
    },
    gridContainer: {
        marginTop: "3rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    image: {
        width: '35rem',
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        [theme.breakpoints.down('sm')]: {
            width: '23rem'
        }
    },
    descContainer: {
        maxWidth: '40rem',
    },
    subtitle: {
        marginBottom: "1em",
        lineHeight: "1.5rem"
    },
    card: {
        maxWidth: '360px',
        height: '100%'
    },
    title: {
        padding: "2rem 0 0 5 rem",
        marginBottom: "1em"
    },
    subTitle: {
        backgroundColor: theme.palette.secondary.light,
        padding: '1rem'
    }
}));

const OurCoaches = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const teams: Team[] = Teams;
    const directors: Director[] = Directors;
    const trainers: Trainer[] = Trainers;
    const [selectedGender, setSelectedGender] = React.useState<string>(Genders.ALL);
    const [selectedType, setSelectedType] = React.useState<string>(Types.ALL);
    const [selectedTeam, setSelectedTeam] = React.useState<string>(Types.ALL);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const filterTeamsArray = () => {
        let filteredResults = teams;
        if (selectedTeam !== Types.ALL){
            filteredResults = teams.filter((team: Team) => team.team === selectedTeam);
        }
        return filteredResults;
    }

    const showGirls = () => {
        return(
            (selectedGender === Genders.FEMALE || selectedGender === Genders.ALL) &&
            (selectedType === Types.TEAMS || selectedType === Types.ALL) &&
            filterTeamsArray().filter((team) =>team.gender === Genders.FEMALE).length > 0
        );
    };

    const showBoys = () => {
        return(
            (selectedGender === Genders.MALE || selectedGender === Genders.ALL) &&
            (selectedType === Types.TEAMS || selectedType === Types.ALL) &&
            filterTeamsArray().filter((team) =>team.gender === Genders.MALE).length > 0
        );
    };

    const showDirectors = () => {
        return(
            (selectedType === Types.DIRECTORS || selectedType === Types.ALL)
        );
    };

    const showTrainers = () => {
        return(
            (selectedType === Types.TRAINERS || selectedType === Types.ALL)
        );
    };

    const girlsTeams = (
        <React.Fragment>
            <Grid container className={classes.subTitle} justifyContent='center'>
                <Grid item >
                    <Typography variant="h4" className={classes.title}>{t('coachInfo.girlsTeams')}</Typography>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.grid}
                spacing={2}
            >
            {filterTeamsArray().filter((team) =>team.gender === Genders.FEMALE).map((team: Team) => (
                <CoachesCard
                    key={team.coach + Math.floor(Math.random() * 1000)}
                    name={team.name}
                    coach={team.coach}
                    coach2={team.coach2}
                    asstCoach={team.asstCoach}
                    asstCoach2={team.asstCoach2}
                    gender={team.gender}
                    age={team.age}
                    imagePath={team.imagePath}
                    team={team.team}/>
            ))}
            </Grid>
        </React.Fragment>
    );

    const boyTeams = (
        <React.Fragment>
            <Grid container className={classes.subTitle} justifyContent='center'>
                <Grid item >
                    <Typography variant="h4" className={classes.title}>{t('coachInfo.boysTeams')}</Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.grid}
                spacing={2}
            >
                {filterTeamsArray().filter((team) =>team.gender === Genders.MALE).map((team: Team) => (
                    <CoachesCard
                        key={team.coach + Math.floor(Math.random() * 1000)}
                        name={team.name}
                        coach={team.coach}
                        coach2={team.coach2}
                        asstCoach={team.asstCoach}
                        gender={team.gender}
                        age={team.age}
                        imagePath={team.imagePath}
                        team={team.team}/>
                ))}
            </Grid>
        </React.Fragment>
    );

    const teamFilters = (
        <React.Fragment>
            <Grid item>
                <TextField
                    name='gender'
                    select
                    onChange={(event) => setSelectedGender(event.target.value)}
                    value={selectedGender}
                    label={t('coachInfo.selectGender')}
                    variant="filled"
                    style={{width:'10rem'}}
                >
                    {(Object.values(Genders) as Array< Genders >).map((option) => (
                        <MenuItem key={Math.floor(Math.random() * 10000)} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item>
                <TextField
                    name='team'
                    select
                    onChange={(event) => setSelectedTeam(event.target.value)}
                    value={selectedTeam}
                    label={t('coachInfo.selectAge')}
                    variant="filled"
                    style={{width:'10rem'}}
                >
                    {(Object.values(Ages) as Array< Ages >).map((option) => (
                        <MenuItem key={Math.floor(Math.random() * 10000)} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </React.Fragment>
    );

    const teamDirectors = (
        <React.Fragment>
            <Grid container className={classes.subTitle} justifyContent='center'>
                <Grid item >
                    <Typography variant="h4" className={classes.title}>{t('coachInfo.directors')}</Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.grid}
                spacing={2}
            >
                {directors.map((director: Director) => (
                    <DirectorsCard
                        key={director.name + Math.floor(Math.random() * 1000)}
                        name={director.name}
                        title={director.title}
                        imagePath={director.imagePath}
                        email={director.email}/>
                ))}
            </Grid>
        </React.Fragment>
    );

    const teamTrainers = (
        <React.Fragment>
            <Grid container className={classes.subTitle} justifyContent='center'>
                <Grid item >
                    <Typography variant="h4" className={classes.title}>{t('coachInfo.trainers')}</Typography>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.grid}
                spacing={2}
            >
                {trainers.map((trainer: Trainer) => (
                    <TrainersCard
                        key={trainer.name + Math.floor(Math.random() * 1000)}
                        name={trainer.name}
                        type={trainer.type}
                        imagePath={trainer.imagePath}/>
                ))}
            </Grid>
        </React.Fragment>
    );

    return (
      <React.Fragment>
          <Grid
              container
              direction="row"
              justifyContent="center"
              className={classes.gridContainer}
              style={{marginTop:'5rem'}}
          >
              <Grid item style={{ paddingRight: matchesSM ? 0 : "5em" }}>
                  <img
                      className={classes.image}
                      alt="coaches pic"
                      src={require('../../assets/Coaches-hero.jpg')}
                  />
              </Grid>
              <Grid
                  item
                  style={{
                      textAlign: matchesSM ? "center" : undefined
                  }}
                  className={classes.descContainer}
              >
                  <Typography variant="h4" className={classes.title}>{t('coachInfo.title')}</Typography>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                      {t('coachInfo.text')}
                  </Typography>
              </Grid>
          </Grid>
          <Grid
              container
              direction="row"
              className={classes.grid}
              spacing={2}
              style={{paddingLeft:'10rem'}}
          >
              <Grid item>
                  <TextField
                      name='type'
                      select
                      onChange={(event) => setSelectedType(event.target.value)}
                      value={selectedType}
                      label={t('coachInfo.selectType')}
                      variant="filled"
                      style={{minWidth:'10rem'}}
                  >
                      {(Object.values(Types) as Array< Types >).map((option) => (
                          <MenuItem key={Math.floor(Math.random() * 10000)} value={option}>
                              {option}
                          </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              {selectedType === Types.ALL ? teamFilters : selectedType === Types.TEAMS ? teamFilters : null}
          </Grid>
          <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.grid}
              spacing={2}
          >
              {showDirectors()? teamDirectors: null}
              {showTrainers()? teamTrainers: null}
            {showGirls()? girlsTeams: null}
            {showBoys()? boyTeams: null}
          </Grid>
      </React.Fragment>
    );
};

export default OurCoaches;