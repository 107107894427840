import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    withStyles
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme:Theme) => ({
    image: {
        width: '100%',
        height: '20rem',
        [theme.breakpoints.down('lg')]: {
            marginTop: '-12px',
            height: '15rem'
        },
        [theme.breakpoints.down('md')]: {
            height: '10rem',
        }
    },
    arrow: {
        color: 'white',
        transform: 'scale(1.3)',
    },
    accordionContainer: {
        backgroundColor: theme.palette.primary.main,
    },
    heading: {
        ...theme.typography.tab,
        color: 'white',
        fontSize: '1.5rem'
    },
    accordionOuterContainer: {
        marginTop: '0.5rem'
    },
    ageGroup: {
        margin: '1rem 0 0 .2rem',
        fontSize: '1.2rem'
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
    table: {
        minWidth: 700,
        [theme.breakpoints.down('md')]: {
            minWidth: '100%',
        }
    },
}));

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

const FAQ = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [ageGroup, setAgeGroup] = React.useState('');
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const currentYear = new Date().getFullYear();
    const nextYear = (new Date().getFullYear() %100)+1;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const handleBirthYear = (e: any)=>{
        if (e.target.value.length === 4) {
            //Determine age group
            const age = currentYear - e.target.value;
            if (age > 18){
                setAgeGroup(t('registration.adult').toString());
            } else if (age < 4){
                setAgeGroup(t('registration.tooYoung').toString());
            } else if (age < 8){
                setAgeGroup(`U${age +1} ${t('registration.micro')} (${t('registration.season')} ${currentYear}/${nextYear})`);
            } else {
                setAgeGroup(`U${age +1} (${t('registration.season')} ${currentYear}/${nextYear})`);
            }
        }
    };

    function createData(ageGroup: string, rosterSize: string, players: string) {
        return { ageGroup, rosterSize, players };
    }

    const rows = [
        createData('U15-U19', t('faq.u15'), '11v11'),
        createData('U13-U14', t('faq.u13'), '11v11'),
        createData('U11-U12', t('faq.u11'), '9v9'),
        createData('U9-U10', t('faq.u9'), '7v7'),
        createData('U8', t('faq.u8'), '4v4'),
    ];

    return (
        <React.Fragment>
            <Grid container direction="column" alignItems='center'>
                <Grid item style={{width: '100%'}}>
                    <img
                        className={classes.image}
                        alt="FAQ pic"
                        src={require('../../assets/faq.png')}
                    />
                </Grid>
                <Grid item style={{margin: matchesSM ? '0 0 1rem 0' : matchesMD ? '1rem':'6rem'}}>
                    <div >
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >

                                <Typography className={classes.heading}>
                                    {t('faq.faq1')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{justifyContent:'center'}}>
                                <form noValidate autoComplete="off">
                                    <TextField id="birthYear"
                                               label={t('faq.birthYearLabel')}
                                               variant="outlined" type="text"
                                               onChange={(e) => handleBirthYear(e)}
                                               inputProps={{ maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*',  max: 4 }} />
                                    <Typography component={'div'} className={classes.ageGroup}>
                                        {ageGroup}
                                    </Typography>
                                </form>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq2')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a2')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq3')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a3')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq4')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a4')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq5')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a5')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq6')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a6')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq7')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a7')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq8')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a8')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq9')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{justifyContent:'center'}}>
                                <Typography component={'span'}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>{t('faq.ageGroup')}</StyledTableCell>
                                                    <StyledTableCell align="right">{t('faq.rosterSize')}</StyledTableCell>
                                                    <StyledTableCell align="right">{t('faq.players')}</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <StyledTableRow key={row.ageGroup}>
                                                        <TableCell component="th" scope="row">
                                                            {row.ageGroup}
                                                        </TableCell>
                                                        <TableCell align="right">{row.rosterSize}</TableCell>
                                                        <TableCell align="right">{row.players}</TableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq10')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a10')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq11')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a11')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq12')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a12')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq13')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a13')}
                                    <a className={classes.link} href="mailto:registrar@EYSC.club">{t('faq.a13Link')}</a>
                                    {t('faq.a13_2')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq14')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    {t('faq.a14')}
                                    <a className={classes.link} href="mailto:DOC@EYSC.club">DOC@EYSC.club</a>
                                    {t('faq.a14_2')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={classes.accordionOuterContainer}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.heading}>
                                    {t('faq.faq15')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'span'}>
                                    <ul>
                                        <li>{t('faq.a15')}</li>
                                        <li>{t('faq.a15_2')}</li>
                                        <li>{t('faq.a15_3')}</li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default FAQ;