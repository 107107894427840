import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Pdf from '../../assets/EYSC-Emergency-Action-Plan-2019.pdf'
import Pdf2 from '../../assets/EAP-Flashcards.pdf'

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        marginTop: "3rem",
        marginBottom: "5rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    image: {
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        [theme.breakpoints.down('sm')]: {
            height: '14rem'
        }
    },
    descContainer: {
        maxWidth: '40rem',
    },
    subtitle: {
        marginBottom: "1em"
    },
    title: {
        marginTop: "1em"
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
}));

const ParentResources = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="column">
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid item style={{ marginRight: matchesSM ? 0 : "5em" }}>
                            <img
                                className={classes.image}
                                alt="Philosophy pic"
                                src={require('../../assets/parent-resources.jpeg')}
                            />
                        </Grid>
                        <Grid
                            item
                            style={{
                                textAlign: matchesSM ? "center" : undefined
                            }}
                            className={classes.descContainer}
                        >
                            <Typography variant="h4" >{t('parentResources.title')}</Typography>
                            <Typography variant="h5" className={classes.title}>{t('parentResources.mailingTitle')}</Typography>
                            <Typography variant="body1" className={classes.subtitle}>
                                {t('parentResources.mailingText')}
                                <a className={classes.link} href="http://eepurl.com/gBAFh1"
                                   rel="noreferrer"
                                   target="_blank">{t('parentResources.mailingLink')}</a>
                            </Typography>
                            <Typography variant="h5" className={classes.title}>{t('parentResources.teamSnapTitle')}</Typography>
                            <Typography variant="body1" className={classes.subtitle}>
                                {t('parentResources.teamSnapText')}
                                <a className={classes.link} href="https://go.teamsnap.com/login/signin"
                                   rel="noreferrer" target="_blank">{t('parentResources.teamSnapTitle')}</a>
                            </Typography>
                            <Typography variant="h5" className={classes.title}>{t('parentResources.emergencyPlanTitle')}</Typography>
                            <Typography variant="body1" className={classes.subtitle}>
                                {t('parentResources.emergencyPlanText')}
                                <a className={classes.link} href={Pdf}target="_blank" rel="noreferrer">
                                    {t('parentResources.emergencyPlanLink')}</a>
                                {t('parentResources.emergencyPlanText2')}
                                <a className={classes.link} href={Pdf2} target="_blank" rel="noreferrer">
                                    {t('parentResources.emergencyPlanLink2')}</a>
                            </Typography>
                            <Typography variant="h5" className={classes.title}>{t('parentResources.affinityTitle')}</Typography>
                            <Typography variant="body1" className={classes.subtitle}>
                                {t('parentResources.affinityText')}
                                <a className={classes.link} href="https://wys-everettysc.sportsaffinity.com/reg/index.asp?sessionguid=&domain=wys%2Deverettysc%2Esportsaffinity%2Ecom"
                                   target="_blank" rel="noreferrer">{t('parentResources.affinityLink')}</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default ParentResources;