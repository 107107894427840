import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        margin: "3rem 0",
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        }
    },
    image: {
        width: '30rem',
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        [theme.breakpoints.down('sm')]: {
            width: '23rem'
        }
    },
    descContainer: {
        maxWidth: '40rem',
    },
    subtitle: {
        marginBottom: "1em",
        lineHeight: "1.5rem"
    },
    title: {
        margin: "1em 0"
    },
    accordionContainer: {
        backgroundColor: theme.palette.primary.main,
    },
    arrow: {
        color: 'white',
        transform: 'scale(1.3)',
    },
    heading: {
        ...theme.typography.tab,
        color: 'white',
        fontSize: '1.5rem'
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
    accordionOuterContainer: {
        marginBottom: '2rem'
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 2.5rem',
        [theme.breakpoints.down("sm")]: {
            margin: 0
        }
    },
    flexChild: {
        // margin: '0 2.5rem',
    }
}));

const CoachInfo = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="column">
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid item style={{ marginRight: matchesSM ? 0 : "5em" }}>
                            <img
                                className={classes.image}
                                alt="micro registration pic"
                                src={require('../../assets/Coaches-hero.jpg')}
                            />
                        </Grid>
                        <Grid
                            item
                            style={{
                                textAlign: matchesSM ? "center" : undefined,
                                padding: matchesSM ? "25px" : 0
                            }}
                            className={classes.descContainer}
                        >
                            <Typography variant="h4" className={classes.title}>{t('volunteer.title')}</Typography>
                            <Typography variant="subtitle1" className={classes.subtitle}>
                                {t('volunteer.subtitle')}
                            </Typography>
                            <Typography variant="h5" className={classes.title}>{t('volunteer.backgroundCheck')}</Typography>
                            <Typography variant="subtitle2" className={classes.subtitle}>
                                {t('volunteer.text')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid
                            item
                            className={classes.descContainer}
                        >
                            <div >
                                {/*<Accordion >*/}
                                {/*    <AccordionSummary*/}
                                {/*        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}*/}
                                {/*        aria-controls="panel1a-content"*/}
                                {/*        id="panel1a-header"*/}
                                {/*        className={classes.accordionContainer}*/}
                                {/*    >*/}
                                {/*        <Typography className={classes.heading}>{t('volunteer.required')}</Typography>*/}
                                {/*    </AccordionSummary>*/}
                                {/*    <AccordionDetails>*/}
                                {/*        <Typography component={'div'}>*/}
                                {/*            <strong>{t('volunteer.safesport')}</strong>*/}
                                {/*            <ul>*/}
                                {/*                <li>{t('volunteer.safesportText')}</li>*/}
                                {/*            </ul>*/}
                                {/*            <div className={classes.flexContainer}>*/}
                                {/*                <div className={classes.flexChild}>*/}
                                {/*                    <strong>{t('volunteer.accessCode')}</strong><br/>*/}
                                {/*                    <strong>{t('volunteer.email')}*/}
                                {/*                        <a className={classes.link} href="mailto:registrar@EYSC.club">registrar@EYSC.club</a>*/}
                                {/*                    </strong>*/}
                                {/*                </div>*/}
                                {/*                <div>*/}
                                {/*                    <Button*/}
                                {/*                        component={'a'}*/}
                                {/*                        href="https://safesport.org/"*/}
                                {/*                        rel='noreferrer noopener'*/}
                                {/*                        target={'_blank'}*/}
                                {/*                        variant='contained'*/}
                                {/*                        color='primary'*/}
                                {/*                    >*/}
                                {/*                        {t('volunteer.safesport')}*/}
                                {/*                    </Button>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}

                                {/*            <strong><br/>{t('volunteer.concussion')}</strong>*/}
                                {/*            <ul>*/}
                                {/*                <li>{t('volunteer.concussionText')}</li>*/}
                                {/*            </ul>*/}
                                {/*            <div className={classes.flexContainer}>*/}
                                {/*                <div className={classes.flexChild}>*/}
                                {/*                    <strong>{t('volunteer.accessCode')}</strong><br/>*/}
                                {/*                    <strong>{t('volunteer.none')}</strong>*/}
                                {/*                </div>*/}
                                {/*                <div>*/}
                                {/*                    <Button*/}
                                {/*                        component={'a'}*/}
                                {/*                        href="https://nfhslearn.com/courses/concussion-in-sports-2"*/}
                                {/*                        rel='noreferrer noopener'*/}
                                {/*                        target={'_blank'}*/}
                                {/*                        variant='contained'*/}
                                {/*                        color='primary'*/}
                                {/*                    >*/}
                                {/*                        {t('volunteer.concussion')}*/}
                                {/*                    </Button>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}

                                {/*            <strong><br/>{t('volunteer.cardiac')}</strong>*/}
                                {/*            <ul>*/}
                                {/*                <li>{t('volunteer.cardiacText')}</li>*/}
                                {/*            </ul>*/}
                                {/*            <div className={classes.flexContainer}>*/}
                                {/*                <div className={classes.flexChild}>*/}
                                {/*                    <strong>{t('volunteer.accessCode')}</strong><br/>*/}
                                {/*                    <strong>{t('volunteer.none')}</strong>*/}
                                {/*                </div>*/}
                                {/*                <div>*/}
                                {/*                    <Button*/}
                                {/*                        component={'a'}*/}
                                {/*                        href="https://nfhslearn.com/courses/61032/sudden-cardiac-arrest"*/}
                                {/*                        rel='noreferrer noopener'*/}
                                {/*                        target={'_blank'}*/}
                                {/*                        variant='contained'*/}
                                {/*                        color='primary'*/}
                                {/*                    >*/}
                                {/*                        {t('volunteer.cardiac')}*/}
                                {/*                    </Button>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </Typography>*/}
                                {/*    </AccordionDetails>*/}
                                {/*</Accordion>*/}
                                {/*<Accordion >*/}
                                {/*    <AccordionSummary*/}
                                {/*        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}*/}
                                {/*        aria-controls="panel1a-content"*/}
                                {/*        id="panel1a-header"*/}
                                {/*        className={classes.accordionContainer}*/}
                                {/*    >*/}
                                {/*        <Typography className={classes.heading}>{t('volunteer.background')}</Typography>*/}
                                {/*    </AccordionSummary>*/}
                                {/*    <AccordionDetails>*/}
                                {/*        <Typography component={'div'}>*/}
                                {/*            <strong>{t('volunteer.backgroundText')}<br/><br/></strong>*/}
                                {/*            <div className={classes.flexContainer}>*/}
                                {/*                <div>*/}
                                {/*                    <Button*/}
                                {/*                        component={'a'}*/}
                                {/*                        href="https://washingtonyouthsoccer.org/resources/risk-management/"*/}
                                {/*                        rel='noreferrer noopener'*/}
                                {/*                        target={'_blank'}*/}
                                {/*                        variant='contained'*/}
                                {/*                        color='primary'*/}
                                {/*                    >*/}
                                {/*                        {t('volunteer.background')}*/}
                                {/*                    </Button>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </Typography>*/}
                                {/*    </AccordionDetails>*/}
                                {/*</Accordion>*/}
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>{t('volunteer.register')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'div'}>
                                            <strong>{t('volunteer.registerText')}<br/><br/></strong>
                                            <div className={classes.flexContainer}>
                                                <div>
                                                    <Button
                                                        component={Link}
                                                        to="/coach-info/coach-application/"
                                                        variant='contained'
                                                        color='primary'
                                                    >
                                                        {t('volunteer.register')}
                                                    </Button>
                                                </div>
                                            </div>
                                            <br/><br/>
                                            <strong>{t('volunteer.emailContacts')}<br/></strong>
                                            <a className={classes.link} href="mailto:doc@EYSC.club">{t('volunteer.select')}</a><br/>
                                            <a className={classes.link} href="mailto:recoperations@eysc.club">{t('volunteer.recreational')}</a><br/>
                                            <a className={classes.link} href="mailto:recoperations@eysc.club">{t('volunteer.micros')}</a><br/>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>{t('volunteer.other')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            <a className={classes.link}
                                               href="https://unitedsoccercoaches.org/education/development-diplomas/"
                                               target="_blank"
                                               rel='noreferrer noopener'
                                            >{t('volunteer.united')}</a><br/>
                                            <a className={classes.link}
                                               href="https://learning.ussoccer.com/coach/courses/available/16/details/1546"
                                               target="_blank"
                                               rel='noreferrer noopener'
                                            >{t('volunteer.usSoccer')}</a><br/>
                                            <a className={classes.link}
                                               href="https://www.positivecoach.org/"
                                               target="_blank"
                                               rel='noreferrer noopener'
                                            >{t('volunteer.positive')}</a><br/>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default CoachInfo;