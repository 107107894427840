import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './components/Theme';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Registration from './pages/Registration';
import MicrosInfo from './pages/programs/MicrosInfo';
import ModsInfo from './pages/programs/ModsInfo';
import SelectInfo from './pages/programs/SelectInfo';
import TournamentsInfo from './pages/programs/TournamentsInfo';
import PracticeFields from './pages/fields/PracticeFields';
import GameFields from './pages/fields/GameFields';
import CoachInfo from './pages/coaches/CoachInfo';
import OurCoaches from './pages/coaches/OurCoaches';
import CoachApplication from './pages/coaches/CoachApplication';
import ParentResources from './pages/resources/ParentResources';
import FAQ from './pages/resources/FAQ';
import RulesPolicy from './pages/resources/RulesPolicy';
import AboutUs from './pages/about/AboutUs';
import OurBoard from './pages/about/OurBoard';
import HandInHand from './pages/about/HandInHand';
import CommunityPartners from './pages/about/CommunityPartners';
import Sponsors from './pages/about/Sponsors';
import ContactUs from './pages/about/ContactUs';
import NavigationHeader from './pages/navigationHeader/NavigationHeader';
import Footer from './pages/footer/Footer';
import { useState } from 'react';
import Philosophy from './pages/programs/Philosophy';
import RefereeIncentive from "./pages/resources/RefereeIncentive";
import PremierInfo from "./pages/programs/PremierInfo";

function App() {
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedAboutIndex, setSelectedAboutIndex] = useState(0);

  return (
      <ThemeProvider theme={theme}>
          <BrowserRouter>
              <NavigationHeader tabIndex={tabIndex} setTabIndex={setTabIndex}
                                selectedAboutIndex={selectedAboutIndex} setSelectedAboutIndex={setSelectedAboutIndex}/>
              <Switch>
                  <Route exact path='/' component={Home} />

                  <Route exact path='/programs/philosophy/' component={Philosophy} />
                  <Route exact path='/programs/micros-info-fees/' component={MicrosInfo} />
                  <Route exact path='/programs/mods-info-fees/' component={ModsInfo} />
                  <Route exact path='/programs/select-info-fees/' component={SelectInfo} />
                  <Route exact path='/programs/premier-info-fees/' component={PremierInfo} />
                  <Route exact path='/programs/tournaments-indoor-play/' component={TournamentsInfo} />

                  <Route exact path='/registration/' component={Registration} />

                  <Route exact path='/practice-fields/' component={PracticeFields} />
                  <Route exact path='/game-fields/' component={GameFields} />

                  <Route exact path='/coach-info/' component={CoachInfo} />
                  <Route exact path='/coach-bios/' component={OurCoaches} />
                  <Route exact path='/coach-info/coach-application/' component={CoachApplication} />

                  <Route exact path='/resources/parent-resources/' component={ParentResources} />
                  <Route exact path='/resources/faq/' component={FAQ} />
                  <Route exact path='/resources/rules-policies/' component={RulesPolicy} />
                  <Route exact path='/resources/referee-incentive/' component={RefereeIncentive} />

                  <Route exact path='/about/' component={AboutUs} />
                  <Route exact path='/about/our-board/' component={OurBoard} />
                  <Route exact path='/about/hand-in-hand/' component={HandInHand} />
                  <Route exact path='/about/community-partners/' component={CommunityPartners} />
                  <Route exact path='/about/sponsors/' component={Sponsors} />
                  <Route exact path='/about/contact-eysc/' component={ContactUs} />

              </Switch>
              <Footer tabIndex={tabIndex} setTabIndex={setTabIndex}
                      selectedAboutIndex={selectedAboutIndex} setSelectedAboutIndex={setSelectedAboutIndex}/>
          </BrowserRouter>
      </ThemeProvider>
  );
}

export default App;