import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';

const microsRegistrationLink = 'https://registration.teamsnap.com/form/7650'
const recRegistrationLink = 'https://registration.teamsnap.com/form/7650'
const useStyles = makeStyles((theme:Theme) => ({
    grid: {
      margin: '6rem 0 2rem 0',
    },
    grid2: {
        margin: '0 0 2rem 0',
    },
    card: {
        maxWidth: '360px',
        height: '100%'
    },
    media: {
        height: '14rem'
    },
    heading: {
        backgroundColor: theme.palette.secondary.main,
        textAlign:'center'
    },
    button: {
        width: '50%',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.main,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.primary.light,
            color: '#e3e0d8',
        }
    },
    select: {
        height: '9.5rem',
        [theme.breakpoints.down('md')]: {
            height: 'unset',
        },
    },
    accordionOuterContainer: {
        marginTop: '2rem'
    },
    accordionContainer: {
        backgroundColor: theme.palette.primary.main,
    },
    accordionHeader: {
        ...theme.typography.tab,
        color: 'white',
        fontSize: '1.5rem'
    },
    arrow: {
        color: 'white',
        transform: 'scale(1.3)',
    },
    descContainer: {
        maxWidth: '40rem',
    },
    ageGroup: {
        margin: '1rem 0 0 .2rem',
        fontSize: '1.2rem'
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
    strongText: {
        fontSize: '1.2rem',
        fontWeight: 800,
        margin: '1rem 0',
    },
    bottomNote: {
        ...theme.typography.tab,
        fontSize: '1rem'
    },
}));

const Registration = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [ageGroup, setAgeGroup] = React.useState('');
    const currentYear = new Date().getFullYear();
    const nextYear = (new Date().getFullYear() %100)+1;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const handleBirthYear = (e: any)=>{
        if (e.target.value.length === 4) {
            //Determine age group
            const age = currentYear - e.target.value;
            if (age > 18){
                setAgeGroup(t('registration.adult').toString());
            } else if (age < 4){
                setAgeGroup(t('registration.tooYoung').toString());
            } else if (age < 8){
                setAgeGroup(`U${age +1} ${t('registration.micro')} (${t('registration.season')} ${currentYear}/${nextYear})`);
            } else {
                setAgeGroup(`U${age +1} (${t('registration.season')} ${currentYear}/${nextYear})`);
            }
        }
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.grid}
                spacing={2}
            >
                {/*Determine Age range*/}
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    className={classes.grid2}
                >
                    <Grid item className={classes.descContainer}>
                        <Accordion className={classes.accordionOuterContainer} expanded={true}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordionContainer}
                            >
                                <Typography className={classes.accordionHeader}>
                                    {t('registration.ageGroupHeader')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <form noValidate autoComplete="off">
                                    <TextField id="birthYear"
                                               label={t('registration.birthYearLabel')}
                                               variant="outlined" type="text"
                                               onChange={(e) => handleBirthYear(e)}
                                               inputProps={{ maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*',  max: 4 }} />
                                    <Typography component={'div'} className={classes.ageGroup}>
                                        {ageGroup}
                                    </Typography>
                                </form>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Grid item>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={require ("../assets/registration-micro.jpeg")}
                                title="Micros Registration"
                            />
                            <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                                {t('registration.microHeader')}
                                <Typography variant="h6"  component="p">
                                    {t('registration.microAge')}
                                </Typography>
                            </Typography>
                            <CardContent>
                                <Typography variant="h6"  component="span">
                                    {t('registration.microSpring')}
                                </Typography>
                                <Typography variant="body1" component="p">
                                    {t('registration.microSpringText1')} <br/>
                                    {t('registration.microSpringText2')}<br/><br/>
                                </Typography>
                                <Typography variant="h6"  component="span">
                                    {t('registration.microFall')}
                                </Typography>
                                <Typography variant="body1" component="p">
                                    {t('registration.microFallText1')}<br/>
                                    {t('registration.microFallText2')}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={'a'}
                                    href={microsRegistrationLink}
                                    rel='noreferrer noopener'
                                    target={'_blank'}
                            >
                                {t('registration.register')}
                            </Button>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={Link}
                                    to="/programs/micros-info-fees/"
                            >
                                {t('registration.learnMore')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={require ("../assets/soccer_fall.jpg")}
                                title="Recreation Registration"
                            />
                            <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                                {t('registration.recHeaderFall')}
                                <Typography variant="h6"  component="div">
                                    {t('registration.recAgeFall')}
                                </Typography>
                            </Typography>
                            <CardContent>
                                <Typography variant="h6"  component="span">
                                    {t('registration.recFall')}
                                </Typography>
                                <Typography variant="body1" component="p">
                                    {t('registration.recFallText1')}<br/>
                                    {t('registration.recFallText2')}<br/><br/>
                                </Typography>
                                <br/><br/><br/><br/><br/>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={'a'}
                                    href={recRegistrationLink}
                                    rel='noreferrer noopener'
                                    target={'_blank'}
                            >
                                {t('registration.register')}
                            </Button>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={Link}
                                    to="/programs/mods-info-fees/"
                            >
                                {t('registration.learnMore')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={require ("../assets/home-philosophy.jpeg")}
                                title="Recreation Registration"
                            />
                            <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                                {t('registration.recHeaderSpring')}
                                <Typography variant="h6"  component="div">
                                    {t('registration.recAgeSpring')}
                                </Typography>
                            </Typography>
                            <CardContent>
                                <Typography variant="h6"  component="span">
                                    {t('registration.recSpring')}
                                </Typography>
                                <Typography variant="body1" component="p">
                                    {t('registration.recSpringText1')} <br/>
                                    {t('registration.recSpringText2')}<br/><br/>
                                </Typography>
                                <br/><br/><br/><br/><br/>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={'a'}
                                    href={recRegistrationLink}
                                    rel='noreferrer noopener'
                                    target={'_blank'}
                                    disabled={true}
                            >
                                {t('registration.register')}
                            </Button>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={Link}
                                    to="/programs/mods-info-fees/"
                            >
                                {t('registration.learnMore')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={require ("../assets/home-tryouts.jpeg")}
                                title="Select Registration"
                            />
                            <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                                {t('registration.selectHeader')}
                                <Typography variant="h6"  component="div">
                                    {t('registration.selectAge')}
                                </Typography>
                            </Typography>
                            <CardContent>
                                <Typography variant="h6"  component="span">
                                    {t('registration.selectYear')}
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.select}>
                                    {t('registration.selectText1')} <br/>
                                    {t('registration.selectText2')}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={'a'}
                                    href='mailto:info@EYSC.club'
                                    rel='noreferrer noopener'
                                    target={'_blank'}
                            >
                                {t('registration.contactUs')}
                            </Button>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={Link}
                                    to="/programs/select-info-fees/"
                            >
                                {t('registration.learnMore')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={require ("../assets/rules_policy.jpg")}
                                title="Premier Registration"
                            />
                            <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                                {t('registration.premierHeader')}
                                <Typography variant="h6"  component="div">
                                    {t('registration.premierAge')}
                                </Typography>
                            </Typography>
                            <CardContent>
                                <Typography variant="h6"  component="span">
                                    {t('registration.selectYear')}
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.select}>
                                    {t('registration.selectText1')} <br/>
                                    {t('registration.selectText2')}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={'a'}
                                    href='mailto:info@EYSC.club'
                                    rel='noreferrer noopener'
                                    target={'_blank'}
                            >
                                {t('registration.contactUs')}
                            </Button>
                            <Button size="medium" color="primary" className={classes.button}
                                    component={Link}
                                    to="/programs/premier-info-fees/"
                            >
                                {t('registration.learnMore')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            {/*Instructions section*/}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                className={classes.grid2}
            >
                <Grid item>
                    <Typography variant="subtitle1" className={classes.bottomNote}>
                        <div className={classes.strongText}>{t('registration.idReq')}</div>
                        {t('registration.questions')} <a className={classes.link} href="mailto:registrar@EYSC.club">registrar@EYSC.club</a>.<br/>
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Registration;