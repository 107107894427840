export interface Board {
    name?: string,
    title: string,
    email: string,
    padding?: boolean,
    paddingTop?: boolean,
}

export const Officers: Board[] = [
    {
        name:"Kelsey Baker",
        title:"board.president",
        email:"president@eysc.club",
        padding: true,
    },
    {
        name:"Porsche Thompson",
        title:"board.vp",
        email:"vicepresident@eysc.club",
        padding: true,
    },
    {
        name:"Barb Lawrence",
        title:"board.secretary",
        email:"registrar@eysc.club",
        padding: true,
    },
    {
        name:"Aidan Richer",
        title:"board.treasurer",
        email:"treasurer@eysc.club",
        padding: true,
    },
    {
        name:"Aidan Richer",
        title:"board.support",
        email:"adminsupport@eysc.club",
        padding: false,
    },
];

export const Other: Board[] = [
    {
        title:"board.scholarship",
        email:"scholarships@eysc.club"
    },
    {
        title:"board.general",
        email:"info@eysc.club"
    },
    {
        title:"board.billing",
        email:"accounts@eysc.club"
    },
];

export const Coordinators: Board[] = [
    {
        name:"Dave Needham",
        title:"board.doc",
        email:"doc@eysc.club",
        padding: true,
    },
    {
        name:"Erika Burke",
        title:"board.recOpps",
        email:"recoperations@eysc.club",
        padding: true,
    },
    {
        name:"Josh Green",
        title:"board.technical",
        email:"technicaldirector@eysc.club",
        padding: true,
    },
    {
        name:"Jessica Lane",
        title:"board.micros",
        email:"micros@eysc.club",
        padding: true,
    },
    {
        name:"Jessica Lane",
        title:"board.sponsorship",
        email:"sponsorship@eysc.club",
        padding: true,
    },
    {
        name:"Andrea Gunder",
        title:"board.fundraising",
        email:"fundraising@eysc.club",
        padding: true,
    },
    {
        name:"AJ Woolsey",
        title:"board.fields",
        email:"fcfields@eysc.club",
        padding: true,
    },
    {
        name:"Todd McNeal",
        title:"board.recFields",
        email:"recfields@eysc.club",
        padding: true,
    },
    {
        name:"TBD",
        title:"board.games",
        email:"games@eysc.club",
        padding: true,
    },
    {
        name:"Alex Swab",
        title:"board.equipment",
        email:"fcequipment@eysc.club",
        padding: true,
    },
    {
        title:"board.recEquipment",
        email:"recequipment@eysc.club",
        padding: true,
        paddingTop: true,
    },
    {
        name:"Morgan Hansen",
        title:"board.photography",
        email:"photography@eysc.club",
        padding: true,
    },
    {
        name:"Porsche Thompson",
        title:"board.socials",
        email:"socialmedia@eysc.club",
        padding: true,
    },
];

export const Facilitators: Board[] = [
    {
        name:"TBD",
        title:"board.tech",
        email:"TBD",
    },
    {
        name:"TBD",
        title:"board.kasch",
        email:"TBD",
    },
];