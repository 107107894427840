export interface Team {
    name: string,
    coach: string,
    coach2?: string,
    asstCoach?: string,
    asstCoach2?: string,
    gender: Genders,
    team: Ages,
    age: string,
    imagePath?: string,
}

export enum Genders {
    ALL = 'All',
    MALE = 'Boys',
    FEMALE = 'Girls',
}

export enum Types {
    ALL = 'All',
    DIRECTORS = 'Directors',
    TRAINERS = 'Specialized Trainers',
    TEAMS = 'Teams'
}

export enum Ages {
    ALL = 'All',
    U9 = 'U9',
    U10 = 'U10',
    U11 = 'U11',
    U12 = 'U12',
    U13 = 'U13',
    U14 = 'U14',
    U15 = 'U15',
    U16 = 'U16',
    U17 = 'U17',
    U18 = 'U18',
    U19 = 'U19',
}

export interface Trainer {
    name: string,
    type: string,
    imagePath?: string,
}

export interface Director {
    name: string,
    title: string,
    email: string,
    imagePath?: string,
}

export const Teams: Team[] = [
    {
        name: "GU10 (2014)",
        coach: "Erika Burke",
        coach2: "JP Matisin",
        gender: Genders.FEMALE,
        age: "coachInfo.u10",
        team: Ages.U10,
        // imagePath:"coach-uphaus"
    },
    {
        name: "GU12 (2012)",
        coach: "Luis Higareda",
        asstCoach: "Gabe Campbell",
        asstCoach2: "Ryan Landes",
        gender: Genders.FEMALE,
        age: "coachInfo.u12",
        team: Ages.U12,
        // imagePath:"coach-vander-veen"
    },
    {
        name: "GU13 (2011)",
        coach: "Calvin Vander Veen",
        gender: Genders.FEMALE,
        age: "coachInfo.u13",
        team: Ages.U13,
    },
    {
        name: "GU14 (2010)",
        coach: "Todd McNeal",
        asstCoach: "Arturo Garcia",
        asstCoach2: "Rafa Marins",
        gender: Genders.FEMALE,
        age: "coachInfo.u14",
        team: Ages.U14,
    },
    {
        name: "GU15 (2009)",
        coach: "Tony Pasinetti",
        asstCoach: "MC Shen",
        gender: Genders.FEMALE,
        age: "coachInfo.u15",
        team: Ages.U15,
    },
    {
        name: "GU16 (2008) NAVY",
        coach: "JP Matisin",
        asstCoach: "Dan Hansen",
        gender: Genders.FEMALE,
        age: "coachInfo.u16",
        team: Ages.U16,
    },
    {
        name: "GU16 (2008) WHITE",
        coach: "AJ Woolsey",
        gender: Genders.FEMALE,
        age: "coachInfo.u16",
        team: Ages.U16,
    },
    {
        name: "GU19 (2005)",
        coach: "Jason Pendleton",
        asstCoach: "Karrie Woods",
        gender: Genders.FEMALE,
        age: "coachInfo.u19",
        team: Ages.U19,
    },


    {
        name: "BU10 (2014)",
        coach: "Abdul Njie",
        asstCoach: "Parker Needham",
        gender: Genders.MALE,
        age: "coachInfo.u10",
        team: Ages.U10,
    },
    {
        name: "BU11 (2013)",
        coach: "Craig Slater",
        asstCoach: "Leo Mia",
        gender: Genders.MALE,
        age: "coachInfo.u11",
        team: Ages.U11,
    },
    {
        name: "BU12 (2012)",
        coach: "Josh Green",
        asstCoach: "Brandon Richer",
        gender: Genders.MALE,
        age: "coachInfo.u12",
        team: Ages.U12,
    },
    {
        name: "BU13 (2011) NAVY",
        coach: "Vincent Gitteau",
        asstCoach: "Wellington Passos",
        gender: Genders.MALE,
        age: "coachInfo.u13",
        team: Ages.U13,
    },
    {
        name: "BU13 (2011) WHITE",
        coach: "Adam Blauvelt",
        gender: Genders.MALE,
        age: "coachInfo.u13",
        team: Ages.U13,
    },
    {
        name: "BU14 (2010)",
        coach: "Josh Green",
        asstCoach: "Yoshi Oba",
        gender: Genders.MALE,
        age: "coachInfo.u14",
        team: Ages.U14,
    },
    {
        name: "BU15 (2009)",
        coach: "Luis Santiago",
        asstCoach: "Mark Swab",
        gender: Genders.MALE,
        age: "coachInfo.u15",
        team: Ages.U15,
        // imagePath:"coach-santiago"
    },
    {
        name: "BU16 (2008) NAVY",
        coach: "Wes Wang",
        gender: Genders.MALE,
        age: "coachInfo.u16",
        team: Ages.U16,
        // imagePath:"coach-wang"
    },
    {
        name: "BU16 (2008) WHITE",
        coach: "Chris Blakely",
        asstCoach: "Erik Petersen",
        gender: Genders.MALE,
        age: "coachInfo.u16",
        team: Ages.U16,
        // imagePath:"coach-wang"
    },
    {
        name: "BU17 (2007) NAVY",
        coach: "Dave Needham",
        asstCoach: "Sean Croteau",
        gender: Genders.MALE,
        age: "coachInfo.u17",
        team: Ages.U17,
    },
    {
        name: "BU17 (2007) WHITE",
        coach: "Dave Needham",
        asstCoach: "TBA",
        gender: Genders.MALE,
        age: "coachInfo.u17",
        team: Ages.U17,
    },
    {
        name: "BU19 (2005) WHITE",
        coach: "Dave Needham",
        gender: Genders.MALE,
        age: "coachInfo.u19",
        team: Ages.U19,
    },
    {
        name: "PREMIER BU19 (2005) NAVY",
        coach: "Vincent Gitteau",
        asstCoach: "Shaun Kjolso",
        gender: Genders.MALE,
        age: "coachInfo.u19",
        team: Ages.U19,
    },
]

export const Trainers: Trainer[] = [
    {
        name: "Luis Santiago",
        type: "Skills Trainer",
        // imagePath:"coach-santiago"
    },
    {
        name: "TBD",
        type: "Goalkeeper Training",
    }
];

export const Directors: Director[] = [
    {
        name: "Kelsey Baker",
        title: "coachInfo.titleVp",
        email: "president@eysc.club",
    },
    {
        name: "Dave Needham",
        title: "coachInfo.titleCoaching",
        email: "doc@eysc.club"
    },
    {
        name: "Josh Green",
        title: "coachInfo.titleTechnical",
        email: "technicaldirector@eysc.club"
    },
];

export interface Form {
    season: string,
    applicant: ApplicantInfo,
    certificates: Certificates,
    experiences: Experience[],
    references: Reference[],
    questions: Questions[],
    checkedAgreement: boolean,
    signature: string,
    dateSigned: string,
}

export interface ApplicantInfo {
    firstName: string,
    lastName: string,
    homePhone: string,
    cellPhone: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipCode: string,
    country: string,
    email: string,
}

export interface Certificates {
    safeSport: string,
    concussion: string,
    cardiac: string,
    backgroundCheck: string,
    levelCoaching: string,
}

export interface Experience {
    year: string,
    club: string,
    age: string,
    league: string,
}

export interface Reference {
    name: string,
    address: string,
    phone: string,
}

export interface Questions {
    question: string,
    answer: string,
}

export const FormValues = {
    season: {
        value:'',
        required: true,
    },
    firstName:{
        value:'',
        required: true,
    },
    lastName:{
        value:'',
        required: true,
    },
    homePhone:{
        value:'',
        regexMessage:'application.invalidPhone',
        regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        required: false,
    },
    cellPhone:{
        value:'',
        regexMessage: 'application.invalidPhone',
        regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        required: true,
    },
    address1:{
        value:'',
        required: true,
    },
    address2:{
        value:'',
        required: false,
    },
    city:{
        value:'',
        required: true,
    },
    state:{
        value:'WA',
        required: true,
    },
    zipCode:{
        value:'',
        required: true,
        regexMessage:'application.invalidFormat',
        regex: /^\d{5}(?:[-\s]\d{4})?$/,
    },
    country:{
        value:'USA',
        required: true,
    },
    email:{
        value:'',
        required: true,
        regexMessage:'application.invalidEmail',
        regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    },
    safeSport:{
        value:'',
        required: true,
    },
    concussion:{
        value:'',
        required: true,
    },
    cardiac:{
        value:'',
        required: true,
    },
    backgroundCheck:{
        value:'',
        required: true,
    },
    levelCoaching:{
        value:'',
        required: false,
    },
    experienceYear1: {
        value:'',
        required: false,
    },
    experienceClub1: {
        value:'',
        required: false,
    },
    experienceAge1: {
        value:'',
        required: false,
    },
    experienceLeague1: {
        value:'',
        required: false,
    },
    experienceYear2: {
        value:'',
        required: false,
    },
    experienceClub2: {
        value:'',
        required: false,
    },
    experienceAge2: {
        value:'',
        required: false,
    },
    experienceLeague2: {
        value:'',
        required: false,
    },
    experienceYear3: {
        value:'',
        required: false,
    },
    experienceClub3: {
        value:'',
        required: false,
    },
    experienceAge3: {
        value:'',
        required: false,
    },
    experienceLeague3: {
        value:'',
        required: false,
    },
    referenceName1: {
        value:'',
        required: true,
    },
    referenceAddress1: {
        value:'',
        required: true,
    },
    referenceTelephone1: {
        value:'',
        regexMessage: 'application.invalidPhone',
        regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        required: true,
    },
    referenceName2: {
        value:'',
        required: true,
    },
    referenceAddress2: {
        value:'',
        required: true,
    },
    referenceTelephone2: {
        value:'',
        regexMessage: 'application.invalidPhone',
        regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        required: true,
    },
    referenceName3: {
        value:'',
        required: true,
    },
    referenceAddress3: {
        value:'',
        required: true,
    },
    referenceTelephone3: {
        value:'',
        regexMessage: 'application.invalidPhone',
        regex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        required: true,
    },
    question1: {
        value:'',
        required: true,
    },
    question2: {
        value:'',
        required: true,
    },
    question3: {
        value:'',
        required: true,
    },
    question4: {
        value:'',
        required: true,
    },
    question5: {
        value:'',
        required: true,
    },
    question6: {
        value:'',
        required: true,
    },
    question7: {
        value:'',
        required: true,
    },
    question8: {
        value:'',
        required: true,
    },
    signature: {
        value:'',
        required: true,
    },
    agree: {
        value: false,
        required: true,
    },
    date: {
        value: new Date(Date.now()).toLocaleString().split(',')[0],
        required: true,
    },
}