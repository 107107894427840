import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import facebook from '../../assets/facebook.svg'
import twitter from '../../assets/twitter.svg'
import instagram from '../../assets/instagram.svg'
import { NavProps } from '../navigationHeader/Navigation.model';

const useStyles = makeStyles((theme:Theme)  => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        minHeight: '17rem',
        position: 'relative',
        [theme.breakpoints.down('lg')]: {
            zIndex: theme.zIndex.modal + 1
        },
        [theme.breakpoints.down('md')]: {
            minHeight: '28rem',
            zIndex: theme.zIndex.modal
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '35rem',
        },
    },
    mainContainer: {
        position: 'absolute',
        paddingTop: '2rem',
        paddingLeft: '3rem',
        width: '100%'
    },
    linkContainer: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    link: {
        color: 'white',
        fontFamily: 'Arial',
        fontSize: '1rem',
        fontWeight: 'bold',
        maxWidth: '40rem',
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: '22px',
        },
    },
    image: {
        [theme.breakpoints.down('lg')]: {
            height: '6rem',
        },
    },
    socialMediaContainer: {
        textAlign: 'center',
    },
    iconContainer: {
        marginTop: '1rem'
    },
    icon: {
        height: '3em',
        width: '3em'
    },
    statement: {
        marginTop: '1rem',
        color: 'white',
        fontFamily: 'Arial',
        fontSize: '1rem',
        fontWeight: 500,
        maxWidth: '50rem',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '35rem',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '25rem',
        },
    },
    copyright: {
        color: 'white',
        fontFamily: 'Arial',
        fontSize: '0.9rem',
    }
}));

export default function Footer(props: NavProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <footer className={classes.footer}>
            <Grid container direction='column'>
                <Grid container justifyContent='center' spacing={10} className={classes.mainContainer}>
                    <Grid item>
                        <Grid container direction='column' className={classes.socialMediaContainer}>
                            <Grid item>
                                <img
                                    src={require('../../assets/logo.png')}
                                    alt='EYSC Logo'
                                    className={classes.image} />
                            </Grid>
                            <Grid container spacing={2} className={classes.iconContainer}>
                                <Grid item className={classes.icon} component={'a'}
                                      href={'https://twitter.com/eysc_1976'}
                                      rel='noreferrer noopener'
                                      target={'_blank'}
                                >
                                    <img alt='Twitter Logo' src={twitter}/>
                                </Grid>
                                <Grid item className={classes.icon} component={'a'}
                                      href={'https://www.facebook.com/EverettYouthSoccerClub'}
                                      rel='noreferrer noopener'
                                      target={'_blank'}
                                >
                                    <img alt='Facebook Logo' src={facebook}/>
                                </Grid>
                                <Grid item className={classes.icon} component={'a'}
                                      href={'https://www.instagram.com/everett_fc_eysc/'}
                                      rel='noreferrer noopener'
                                      target={'_blank'}
                                >
                                    <img alt='Instagram Logo' src={instagram}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container direction='column'>
                            <Grid container direction='row' spacing={6}>
                                <Grid item className={classes.link}
                                      component={Link} to={'/'}
                                      onClick={() => props.setTabIndex(0)}
                                >
                                    {t('footer.home')}
                                </Grid>
                                <Grid item className={classes.link}
                                      component={Link} to={'/about/contact-eysc/'}
                                      onClick={() => {props.setTabIndex(6); props.setSelectedAboutIndex(5)}}
                                >
                                    {t('footer.contact')}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item className={classes.statement}>
                                    {t('footer.text')}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' alignItems='flex-end'>
                        <Grid item className={classes.copyright}>
                            © {new Date().getFullYear()}{t('footer.copyright')}
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </footer>);
}