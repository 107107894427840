import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { MenuProps } from './Navigation.model';


const useStyles = makeStyles((theme:Theme) => ({
    menu: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    menuItem: {
        ...theme.typography.tab,
        opacity: 0.7,
        fontWeight: 600,
        '&:hover': {
            opacity: 1,
            color: theme.palette.secondary.main,
        }
    },
    selected: {
      color: theme.palette.secondary.main,
    }
}));

const MenuComponent = (props: MenuProps) => {
    const classes = useStyles();


    const handleClose = () => {
        props.setAnchorEl(null);
        props.setOpenMenu(false);
    };

    const handleMenuOptionsClick = (event: any, index: number) => {
        props.setAnchorEl(null);
        props.setOpenMenu(false);
        props.setSelectedIndex(index);
    };
    return (
        <Menu open={props.openMenu}
              id={props.id}
              anchorEl={props.anchorEl}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              classes={{paper: classes.menu}}
              MenuListProps={{onMouseLeave: handleClose }}
              elevation={0}
              style={{ zIndex: 1302 }}
              keepMounted
        >
            {props.menuOptions.map((option: any, index: number) => (
                <MenuItem
                    key={option.link + option.href}
                    component={Link}
                    to={option.link ? option.link : option.href}
                    target={option.href ? '_blank' : undefined}
                    onClick={event =>{
                        handleMenuOptionsClick(event, index);
                        handleClose();
                        props.onSetTab(props.tabIndex);
                    }}
                    classes={{root: classes.menuItem, selected: classes.selected }}
                    selected={index === props.selectedIndex && props.selectedTab === props.tabIndex}
                >{option.name} </MenuItem>
            ))}
        </Menu>
    );
};

export default MenuComponent;