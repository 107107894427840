import React from 'react';
import { Card, CardActionArea, CardContent} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Board} from "./Board.model";

const useStyles = makeStyles((theme:Theme) => ({
    card: {
        width: '300px',
        height: '100%'
    },
    media: {
        height: '14rem',
        backgroundPosition: 'top',
    },
    heading: {
        backgroundColor: theme.palette.secondary.light,
        textAlign:'center'
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
}));

const BoardCard = (props: Board) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const email = "mailto:" + props.email;
    return (
        <React.Fragment>
            <Grid item>
                <Card className={classes.card}>
                    <CardActionArea>
                        <Typography gutterBottom variant="h5"
                                    component="h2" style={{ padding: props.padding? '1rem 0': 0}}
                                    className={classes.heading}>
                            {t(props.title)}
                        </Typography>
                        <CardContent>
                            <Typography variant="h6"  component="span">
                                {props.name ? props.name: props.paddingTop ? <>&nbsp;</> : null}

                            </Typography>
                            <Typography variant="body1" component="p">
                                <a className={classes.link} href={email}>{props.email}</a><br/><br/>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </React.Fragment>
    );
};

export default BoardCard;