import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
//Import all translation files
import translationEnglish from "./English/translation.json";
import translationSpanish from "./Spanish/translation.json";
import translationArabic from "./Arabic/translation.json";
import translationKorean from "./Korean/translation.json";
import translationRussian from "./Russian/translation.json";
import translationUkrainian from "./Ukrainian/translation.json";
import translationVietnamese from "./Vietnamese/translation.json";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: translationEnglish,
            },
            es: {
                translation: translationSpanish,
            },
            ar: {
                translation: translationArabic,
            },
            kr: {
                translation: translationKorean,
            },
            ru: {
                translation: translationRussian,
            },
            ua: {
                translation: translationUkrainian,
            },
            vn: {
                translation: translationVietnamese,
            }
        }
    });

export default i18n;