import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        marginTop: "3rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    image: {
        width: '28rem',
        marginLeft: '1rem',
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        [theme.breakpoints.down('sm')]: {
            width: '15rem'
        }
    },
    tournamentImage: {
        height: '13rem',
        marginTop: '2rem'
    },
    // indoorImage: {
    //     maxWidth: '40rem',
    //     marginBottom: '3rem',
    //     [theme.breakpoints.down('sm')]: {
    //         maxWidth: '20rem'
    //     }
    // },
    tournamentNote: {
        ...theme.typography.tab,
        fontSize: '1rem',
        maxWidth: '20rem'
    },
    descContainer: {
        maxWidth: '40rem',
    },
    subtitle: {
        marginBottom: "1em",
        lineHeight: "1.5rem"
    },
    title: {
        margin: "1em 0"
    },
    selectTitle: {
        marginTop: "3em",
        maxWidth: "40rem"
    }
}));

const TournamentsInfo = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
      <React.Fragment>
          <Grid container direction="column">
              <Grid item>
                  {" "}
                  <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      className={classes.gridContainer}
                  >
                      <Grid item style={{ marginRight: matchesSM ? 0 : "5em" }}>
                          <img
                              className={classes.image}
                              alt="Tournaments pic"
                              src={require('../../assets/Bigfoot-hero.jpg')}
                          />
                      </Grid>
                      <Grid
                          item
                          className={classes.descContainer}
                      >
                          <Typography variant="h4" className={classes.title}>{t('tournaments.title')}</Typography>
                          <Typography variant="subtitle1" className={classes.subtitle}>
                              {t('tournaments.subtitle')}
                          </Typography>
                      </Grid>
                  </Grid>
              </Grid>
              {/*Select soccer Tournaments*/}
              <Grid item style={{ marginRight: matchesSM ? 0 :"10rem" }}>
                  <Grid container
                        direction="column"
                        style={{ alignItems: matchesSM ? "center" :"flex-end", textAlign: matchesSM ? "center" :"left"}}>
                      <Grid
                          item
                          style={{
                              textAlign: matchesSM ? "center" : "left"
                          }}
                          className={classes.descContainer}
                      >
                          <Typography variant="h4" className={classes.selectTitle}>{t('tournaments.select')}</Typography>
                      </Grid>
                      <Grid item>
                          <Grid container direction='row' spacing={2}
                                style={{
                                    justifyContent: matchesSM ? "center" : "left",
                                }}>
                              <Grid item >
                                  <Grid container direction='column'>
                                      <Grid item>
                                          <img
                                              className={classes.tournamentImage}
                                              alt="SoundersCup pic"
                                              src={require('../../assets/sounders-fc-cup-2022.jpg')}
                                          />
                                      </Grid>
                                      <Grid item>
                                          <Typography variant="subtitle1" className={classes.tournamentNote}>
                                              {t('tournaments.soundersCup')}
                                          </Typography>
                                      </Grid>
                                  </Grid>
                              </Grid>
                              <Grid item>
                                  <Grid container direction='column'>
                                      <Grid item>
                                          <img
                                              className={classes.tournamentImage}
                                              alt="Xtreme Cup pic"
                                              src={require('../../assets/xtreme-cup-2022.png')}
                                          />
                                      </Grid>
                                      <Grid item>
                                          <Typography variant="subtitle1" className={classes.tournamentNote}>
                                              {t('tournaments.xtremeCup')}
                                          </Typography>
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </Grid>
                          <Grid container direction='row' spacing={2}
                                style={{
                                    justifyContent: matchesSM ? "center" : "left",
                                }}>
                              <Grid item >
                                  <Grid container direction='column'>
                                      <Grid item>
                                          <img
                                              className={classes.tournamentImage}
                                              alt="Labor Day Cup pic"
                                              src={require('../../assets/laborday-cup.png')}
                                          />
                                      </Grid>
                                      <Grid item>
                                          <Typography variant="subtitle1" className={classes.tournamentNote}>
                                              {t('tournaments.laborDayCup')}
                                          </Typography>
                                      </Grid>
                                  </Grid>
                              </Grid>
                              <Grid item>
                                  <Grid container direction='column'>
                                      <Grid item>
                                          <img
                                              className={classes.tournamentImage}
                                              alt="WPl Tournament pic"
                                              src={require('../../assets/wpl-tournament.png')}
                                          />
                                      </Grid>
                                      <Grid item>
                                          <Typography variant="subtitle1" className={classes.tournamentNote}>
                                              {t('tournaments.wacup')}
                                          </Typography>
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Grid>
                  </Grid>
              </Grid>
              {/*Rec soccer Tournaments*/}
              <Grid item style={{ marginLeft: matchesSM ? 0 :"10rem", marginTop: matchesSM ? "5rem": 0}}>
                  <Grid container
                        direction="column"
                        style={{ alignItems: matchesSM ? "center" :"flex-start", textAlign: matchesSM ? "center" :"left"}}
                  >
                      <Grid
                          item
                          style={{
                              textAlign: matchesSM ? "center" : "left"
                          }}
                          className={classes.descContainer}
                      >
                          <Typography variant="h4" >{t('tournaments.rec')}</Typography>
                      </Grid>
                      <Grid item>
                          <Grid container direction='row' spacing={2}
                                style={{
                                    justifyContent: matchesSM ? "center" : "left",
                                }}>
                              <Grid item >
                                  <Grid container direction='column'>
                                      <Grid item>
                                          <img
                                              className={classes.tournamentImage}
                                              alt="Turkeyshoot pic"
                                              src={require('../../assets/turkeyshoot.png')}
                                          />
                                      </Grid>
                                      <Grid item>
                                          <Typography variant="subtitle1" className={classes.tournamentNote}>
                                              {t('tournaments.turkeyShoot')}
                                          </Typography>
                                      </Grid>
                                  </Grid>
                              </Grid>
                              <Grid item>
                                  <Grid container direction='column'>
                                      <Grid item>
                                          <img
                                              className={classes.tournamentImage}
                                              alt="Washington Rec Cup pic"
                                              src={require('../../assets/wa-rec-cup.jpeg')}
                                          />
                                      </Grid>
                                      <Grid item>
                                          <Typography variant="subtitle1" className={classes.tournamentNote}>
                                              {t('tournaments.waRecCup')}
                                          </Typography>
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>

      </React.Fragment>
    );
};

export default TournamentsInfo;