import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import emailIcon from "../../assets/email.svg";
import facebookIcon from "../../assets/facebook_blue.svg";
import twitterIcon from "../../assets/twitter_blue.svg";
import instaIcon from "../../assets/instagram_blue.svg";

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        marginTop: "3rem",
        marginBottom: "5rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    image: {
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            height: '14rem'
        }
    },
    descContainer: {
        maxWidth: '40rem',
    },
    subtitle: {
        marginBottom: "1em",
        lineHeight: "1.5rem"
    },
    title: {
        marginBottom: "1em"
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
    icon: {
        height: '2em',
        width: '2em',
        marginRight: '1rem'
    },
}));

const ContactUs = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="column">
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid item style={{ marginRight: matchesSM ? 0 : "5em" }}>
                            <img
                                className={classes.image}
                                alt="Contact Us pic"
                                src={require('../../assets/contact-us.png')}
                            />
                        </Grid>
                        <Grid
                            item
                            style={{
                                textAlign: matchesSM ? "center" : undefined
                            }}
                            className={classes.descContainer}
                        >
                            <Typography variant="h4" className={classes.title}>{t('contact.title')}</Typography>
                            <Typography variant="subtitle1" className={classes.subtitle}>
                                {t('contact.text1')}<br/><br/>
                                {t('contact.text2')}
                            </Typography>
                            {/*Email*/}
                            <Grid container direction="column">
                                <Grid item container>
                                    <Grid item>
                                        <img
                                            src={emailIcon}
                                            alt="email"
                                            style={{ margin: "0.3em 0.5em 0 0" }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="subtitle1"
                                            style={{lineHeight: "1.5rem"}}
                                        >
                                            <a className={classes.link} href="mailto:info@EYSC.club">
                                                info@EYSC.club
                                            </a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*Mail*/}
                            <Grid container>
                                <Grid item>
                                    <Typography variant="subtitle1" style={{lineHeight: "1.5rem"}}>
                                        <br/>
                                        <b>{t('contact.address')}</b><br/>
                                        Everett Youth Soccer Club <br/>
                                        PO Box 2831<br/>
                                        Everett WA 98213<br/><br/>
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/*Social Media*/}
                            <Grid container direction="column">
                                <Grid item container>
                                    <Grid item component={'a'} className={classes.icon}
                                          href={'https://twitter.com/eysc_1976'}
                                          rel='noreferrer noopener'
                                          target={'_blank'}
                                    >
                                        <img alt='Twitter Logo' src={twitterIcon}/>
                                    </Grid>
                                    <Grid item component={'a'} className={classes.icon}
                                          href={'https://www.facebook.com/EverettYouthSoccerClub'}
                                          rel='noreferrer noopener'
                                          target={'_blank'}
                                    >
                                        <img alt='Facebook Logo' src={facebookIcon}/>
                                    </Grid>
                                    <Grid item component={'a'} className={classes.icon}
                                          href={'https://www.instagram.com/everett_fc_eysc/'}
                                          rel='noreferrer noopener'
                                          target={'_blank'}
                                    >
                                        <img alt='Instagram Logo' src={instaIcon}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default ContactUs;