export interface GeoLocation {
    name: string,
    location: {
        lat: number,
        lng: number,
        address: string,
        href: string,
    }
}
export const PracticeFieldLocations: GeoLocation[] = [
    {
        name: "Kasch Park",
        location: {
            lat: 47.914688,
            lng: -122.262833,
            address: '8811 Airport Rd., Everett WA 98204',
            href: 'https://goo.gl/maps/Rv1uF2xjLrcoWB3g6'
        }
    },
    {
        name: "Jackson Elementary",
        location: {
            lat: 47.967120,
            lng: -122.218430,
            address: '3700 Federal Ave, Everett WA 98201',
            href: 'https://goo.gl/maps/rigRi4J5Vi719Nqj7'
        }
    },
    {
        name: "Lowell Elementary",
        location: {
            lat: 47.951010,
            lng: -122.202430,
            address: '5010 View Dr., Everett WA 98203',
            href: 'https://goo.gl/maps/MWkCHcpV95YHBv2o8'
        }
    },
    {
        name: "Madison Elementary",
        location: {
            lat: 47.940130,
            lng: -122.224010,
            address: '616 Pecks Dr, Everett WA 98203',
            href: 'https://goo.gl/maps/WM42tqDwa4y2HXK79'
        }
    },
    {
        name: "Sequoia High School",
        location: {
            lat: 47.982567,
            lng: -122.193375,
            address: '3516 Rucker Ave, Everett 98201',
            href: 'https://maps.app.goo.gl/EswAptVEtbSXcvCS8'
        }
    },
    {
        name: "View Ridge Elementary",
        location: {
            lat: 47.952620,
            lng: -122.230130,
            address: '202 Alder St, Everett WA 98203',
            href: 'https://goo.gl/maps/nkuvjWq4NaC6sydB9'
        }
    },
    {
        name: "Whittier Elementary School",
        location: {
            lat: 48.004867,
            lng:  -122.204852,
            address: '916 Oakes Ave, Everett 98201',
            href: 'https://maps.app.goo.gl/18uiM8dNYT6kjjb18'
        }
    },
];

export const GameFieldLocations: GeoLocation[] = [
    {
        name: "152nd St Soccer Complex",
        location: {
            lat: 48.133870,
            lng: -122.166940,
            address: '4808 152nd St NE, Marysville 98270',
            href: 'https://goo.gl/maps/Ch31p6kAcRg2kdiR9'
        }
    },
    {
        name: "Cedar Valley Community School",
        location: {
            lat: 47.823760,
            lng: -122.309140,
            address: '19200 56th Ave W, Lynnwood WA 98036',
            href: 'https://goo.gl/maps/REUhoc9BQGaSW3jV8'
        }
    },
    {
        name: "Cedarhome",
        location: {
            lat: 48.249420,
            lng: -122.322290,
            address: '27911 68th Ave NW Stanwood 98292',
            href: 'https://goo.gl/maps/v7WgEYTNHyiRE7Jb9'
        }
    },
    {
        name: "Eisenhower Middle School",
        location: {
            lat: 47.904630,
            lng: -122.199790,
            address: '10200 25th Ave SE, Everett WA 98208',
            href: 'https://goo.gl/maps/VstEYNsKQiu1EeGVA'
        }
    },
    {
        name: "Explorer Middle School",
        location: {
            lat: 47.909770,
            lng: -122.245800,
            address: '9600 Sharon Dr., Everett 98204',
            href: 'https://goo.gl/maps/AZRMSAU13a8n1Vtv5'
        }
    },
    {
        name: "Fairfield Park",
        location: {
            lat: 47.862469,
            lng: -122.009575,
            address: '15532 Fryelands Blvd SE, Monroe 98272',
            href: 'https://goo.gl/maps/g8kiHDDYJvHVeBXU6'
        }
    },
    {
        name: "Gateway Middle School",
        location: {
            lat: 47.862034,
            lng: -122.155495,
            address: '15404 Silver Firs Dr, Everett WA 98208',
            href: 'https://goo.gl/maps/DLyEoEr69RTGTZvf6'
        }
    },
    {
        name: "Granite Falls High School",
        location: {
            lat: 48.086704,
            lng: -121.984044,
            address: '1401 100th St. NE, Granite Falls 98252',
            href: 'https://goo.gl/maps/JUXpx1STJnoSCafL9'
        }
    },
    {
        name: "Kamiak High School",
        location: {
            lat: 47.90387,
            lng: -122.301529,
            address: '10801 Harbour Point Blvd., Mukilteo 98275',
            href: 'https://goo.gl/maps/9SZaJRphRUuu3uqn9'
        }
    },
    {
        name: "Kasch Park",
        location: {
            lat: 47.919381,
            lng: -122.27152,
            address: '8811 Airport Rd., Everett 98204',
            href: 'https://goo.gl/maps/LrjwFxtB5NFYSBCc6'
        }
    },
    {
        name: "Lochsloy Fields",
        location: {
            lat: 48.0171,
            lng: -122.0672,
            address: '6710 147th Ave. N.E., Lake Stevens 98258',
            href: 'https://goo.gl/maps/mFX7MRnFHBTACUndA'
        }
    },
    {
        name: "Lowell Elementary",
        location: {
            lat: 47.951798,
            lng: -122.204263,
            address: '5010 W. View Dr., Everett 98203',
            href: 'https://goo.gl/maps/DEFWfTnUKXKpwAD57'
        }
    },
    {
        name: "Lynnwood High School",
        location: {
            lat: 47.833761,
            lng: -122.239618,
            address: '18218 North Rd, Bothell 98012',
            href: 'https://goo.gl/maps/DEFWfTnUKXKpwAD57'
        }
    },
    {
        name: "Madison Elementary",
        location: {
            lat: 47.94178,
            lng: -122.224003,
            address: '616 Pecks Dr, Everett WA 98203',
            href: 'https://goo.gl/maps/daCn64fAMfTJzBVYA'
        }
    },
    {
        name: "Mariner High School",
        location: {
            lat: 47.889214,
            lng: -122.236298,
            address: '200 120th St SW, Everett 98204',
            href: 'https://goo.gl/maps/pqa2UdMB7WhtzTrG9'
        }
    },
    {
        name: "Mill Creek Sports Park",
        location: {
            lat: 47.872471,
            lng: -122.21917,
            address: '13951 N Creek Dr., Mill Creek, WA 98012',
            href: 'https://goo.gl/maps/h7QpiYr7s8Gb471SA'
        }
    },
    {
        name: "Mountlake Terrace High School",
        location: {
            lat: 47.803983,
            lng: -122.292226,
            address: '21801 44th Ave W, Mountlake Terrace 98043',
            href: 'https://goo.gl/maps/TwCB4B4vr7HEcKAN6'
        }
    },
    {
        name: "Olympic View Middle School",
        location: {
            lat: 47.930865,
            lng: -122.301114,
            address: '2602 Mukilteo Speedway, Mukilteo 98275',
            href: 'https://goo.gl/maps/bEyLi1eHrozjxyLS8'
        }
    },
    {
        name: "Osprey Park",
        location: {
            lat: 47.869191,
            lng: -121.820584,
            address: '801 1st Street, Sultan 98294',
            href: 'https://goo.gl/maps/jEtSoNEfnsYZsvmt5'
        }
    },
    {
        name: "Sequoia High School",
        location: {
            lat: 47.982567,
            lng: -122.193375,
            address: '3516 Rucker Ave, Everett 98201',
            href: 'https://maps.app.goo.gl/EswAptVEtbSXcvCS8'
        }
    },
    {
        name: "Silver Lake Elementary School",
        location: {
            lat: 47.865116,
            lng: -122.152596,
            address: '5909 146th Pl SE, Everett WA 98208',
            href: 'https://goo.gl/maps/rMWmgJVTXQnoktzS7'
        }
    },
    {
        name: "Stanwood Elementary",
        location: {
            lat: 48.244695,
            lng: -122.371037,
            address: '10227 273rd PL NW Stanwood WA 98292',
            href: 'https://goo.gl/maps/fTbPP6kZV4LNcDAb9'
        }
    },
    {
        name: "Stocker Fields",
        location: {
            lat: 47.907734,
            lng: -122.087440,
            address: '11 Lincoln Ave. Snohomish WA 98290',
            href: 'https://goo.gl/maps/Pb2YaoXsTjEzxn8u7'
        }
    },
    {
        name: "Walter Hall",
        location: {
            lat: 47.921833,
            lng: -122.2556,
            address: '1226 W. Casino Rd., Everett WA 98204',
            href: 'https://goo.gl/maps/AG9yvAsovsXcWVA6A'
        }
    },
    {
        name: "Whittier Elementary School",
        location: {
            lat: 48.004867,
            lng:  -122.204852,
            address: '916 Oakes Ave, Everett 98201',
            href: 'https://maps.app.goo.gl/18uiM8dNYT6kjjb18'
        }
    },
];