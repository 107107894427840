import React from 'react';
import {Card, CardActionArea, CardActions, CardMedia} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Sponsor} from "./Sponsors.model";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme:Theme) => ({
    card: {
        width: '300px',
        height: '100%'
    },
    media: {
        height: '20rem',
        backgroundSize: 'contain'
    },
    button: {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.main,
        },
    },
}));

const SponsorsCard = (props: Sponsor) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Grid item>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={require (`../../../public/assets/${props.imagePath}`)}
                            title="Sponsor Logo"
                        />
                    </CardActionArea>
                    <CardActions>
                        <Button size="medium" color="primary" className={classes.button}
                                component={'a'}
                                href={props.url}
                                rel='noreferrer noopener'
                                target={'_blank'}
                        >
                            {t(props.name)}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </React.Fragment>
    );
};

export default SponsorsCard;