import { Form, FormValues } from './Coaches.model';

export const getFormParams = (formValues: typeof FormValues, t:any) => {
    const formParams: Form = {
        season: formValues.season.value,
        applicant: {
            firstName: formValues.firstName.value,
            lastName: formValues.lastName.value,
            cellPhone: formValues.cellPhone.value,
            homePhone: formValues.homePhone.value,
            address1: formValues.address1.value,
            address2: formValues.address2.value,
            city: formValues.city.value,
            state: formValues.state.value,
            zipCode: formValues.zipCode.value,
            country: formValues.country.value,
            email: formValues.email.value,
        },
        certificates: {
            safeSport: formValues.safeSport.value,
            concussion: formValues.concussion.value,
            cardiac: formValues.cardiac.value,
            backgroundCheck: formValues.backgroundCheck.value,
            levelCoaching: formValues.levelCoaching.value,
        },
        experiences: [
            {
                year: formValues.experienceYear1.value,
                club: formValues.experienceClub1.value,
                age: formValues.experienceAge1.value,
                league: formValues.experienceLeague1.value,
            },
            {
                year: formValues.experienceYear2.value,
                club: formValues.experienceClub2.value,
                age: formValues.experienceAge2.value,
                league: formValues.experienceLeague2.value,
            },
            {
                year: formValues.experienceYear3.value,
                club: formValues.experienceClub3.value,
                age: formValues.experienceAge3.value,
                league: formValues.experienceLeague3.value,
            },
        ],
        references: [
            {
                name: formValues.referenceName1.value,
                address: formValues.referenceAddress1.value,
                phone: formValues.referenceTelephone1.value,
            },
            {
                name: formValues.referenceName2.value,
                address: formValues.referenceAddress2.value,
                phone: formValues.referenceTelephone2.value,
            },
            {
                name: formValues.referenceName3.value,
                address: formValues.referenceAddress3.value,
                phone: formValues.referenceTelephone3.value,
            },
        ],
        questions: [
            {
                question: t('application.question1'),
                answer: formValues.question1.value,
            },
            {
                question: t('application.question2'),
                answer: formValues.question2.value,
            },
            {
                question: t('application.question3'),
                answer: formValues.question3.value,
            },
            {
                question: t('application.question4'),
                answer: formValues.question4.value,
            },
            {
                question: t('application.question5'),
                answer: formValues.question5.value,
            },
            {
                question: t('application.question6'),
                answer: formValues.question6.value,
            },
            {
                question: t('application.question7'),
                answer: formValues.question7.value,
            },
            {
                question: t('application.question8'),
                answer: formValues.question8.value,
            },
        ],
        checkedAgreement: formValues.agree.value,
        signature: formValues.signature.value,
        dateSigned: formValues.date.value,
    }
    return formParams;
}