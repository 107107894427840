import { createTheme } from '@mui/material/styles';

const primaryBlue = '#010736';
const primaryYellow = '#fccf2e';
const arcGrey = "#4b535e";

const theme = createTheme({
    palette: {
        common: {
          primaryBlue,
          primaryYellow,
        },
        primary: {
            main: `${primaryBlue}`,
        },
        secondary: {
            main: `${primaryYellow}`,
        },
    },
    overrides: {
        MuiInputLabel: {
            root: {
                color: primaryBlue,
                fontSize: '1rem'
            }
        },
        MuiInput: {
            underline: {
                '&:before':{
                    borderBottom: `2px solid ${primaryBlue}`
                },
                '&:hover:not($disabled):not($focused):not($error):before':{
                    borderBottom: `2px solid ${primaryBlue}`
                }
            }
        }
    },
    typography: {
        tab: {
            fontFamily: "Raleway",
            textTransform: "none",
            fontWeight: 700,
            fontSize: "1rem",
        },
        h4: {
            fontFamily: "Raleway",
            fontSize: "1.75rem",
            color: `${primaryBlue}`,
            fontWeight: 700
        },
        subtitle1: {
            fontSize: "1.25rem",
            fontWeight: 300,
            color: `${arcGrey}`,
        },
        subtitle2: {
            fontSize: "1.25rem",
            fontWeight: 300,
            color: `${primaryBlue}`,
        },
    },
});

export default theme;
