export interface Sponsor {
    name: string,
    imagePath: string,
    url: string,
}

export const Partners: Sponsor[] = [
    {
        name:"Hand in Hand",
        imagePath: "HIH-Logo.jpeg",
        url: "https://handinhandkids.org/"
    },
    {
        name:"Everett Jets FC",
        imagePath: "Everett Jets FC Logo.jpeg",
        url: "https://www.everettjetsfc.com/"
    },
    {
        name:"LS Football",
        imagePath: "ls-football.jpeg",
        url: "https://www.facebook.com/lsfootball10/"
    },
    {
        name:"Everett Junior Cheer",
        imagePath: "EJC LOGO.jpeg",
        url: "https://www.everettjuniorcheer.com/"
    },
    {
        name:"Everett Downtown Storage",
        imagePath: "everett-downtown-storage-logo.png",
        url: "https://everettdowntownstorage.com/"
    },
];

export const SponsorsList: Sponsor[] = [
    {
        name:"Ziply Fiber",
        imagePath: "ziply-fiber.jpeg",
        url: "https://ziplyfiber.com/"
    },
    {
        name:"Pellegrini Orthodontics",
        imagePath: "pellegriniortho_weblogo.jpg",
        url: "https://www.everettbraces.com/"
    },
    {
        name:"Target",
        imagePath: "target.jpeg",
        url: "https://www.target.com/"
    },
    {
        name:"Dick's Sporting Goods",
        imagePath: "dicks-sporting-goods.jpeg",
        url: "https://www.dickssportinggoods.com/"
    },
    {
        name:"Seattle Children's",
        imagePath: "childrens-north-everett.png",
        url: "https://tinyurl.com/8ecdcfyc"
    },
    {
        name:"North Cross Aluminum",
        imagePath: "north-crss-aluminum.jpeg",
        url: "https://northcrossaluminum.com/"
    },
    {
        name:"Broadway Auto Sales",
        imagePath: "broadway-auto.png",
        url: "https://www.broadwayautosalesofeverett.com/"
    },
    {
        name:"Puyallup RV",
        imagePath: "puyalluprvsales-logo.png",
        url: "https://www.puyalluprv.com/"
    },
    {
        name:"Everett Downtown Storage",
        imagePath: "everett-downtown-storage-logo.png",
        url: "https://everettdowntownstorage.com/"
    },
    {
        name:"Stack Auto Rebuild",
        imagePath: "stack-auto-rebuild.jpeg",
        url: "https://stackautorebuild.com/"
    },
    {
        name:"Red Robin",
        imagePath: "red_robin.png",
        url: "https://www.redrobin.com/kids-half"
    },
];