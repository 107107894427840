import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Pdf from '../../assets/club-policies.pdf';
import Pdf2 from '../../assets/anti-bullyingpolicy2019.pdf';

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        marginTop: "3rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    image: {
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        [theme.breakpoints.down('sm')]: {
            height: '10rem'
        }
    },
    descContainer: {
        maxWidth: '40rem',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    },
    subtitle: {
        marginBottom: "1em",
        lineHeight: "1.5rem"
    },
    title: {
        margin: "1em 0"
    },
    arrow: {
        color: 'white',
        transform: 'scale(1.3)',
    },
    accordionContainer: {
        backgroundColor: theme.palette.primary.main,
    },
    heading: {
        ...theme.typography.tab,
        color: 'white',
        fontSize: '1.5rem'
    },
    accordionOuterContainer: {
        marginTop: '0.5rem'
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
    subHeading: {
        marginBottom: '0.1rem'
    },
    button: {
        borderRadius: '50px',
        fontSize: '1rem',
        height: '45px',
        marginBottom: '2rem',
        '&:hover':{
            backgroundColor: theme.palette.primary.light
        },
        [theme.breakpoints.down('sm')]: {
            height: '80px',
        },
    }
}));

const RulesPolicy = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="column">
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid item style={{ paddingRight: matchesSM ? 0 : "5em" }}>
                            <img
                                className={classes.image}
                                alt="Rules $ Policy pic"
                                src={require('../../assets/rules_policy.jpg')}
                            />
                        </Grid>
                        <Grid
                            item
                            style={{
                                textAlign: matchesSM ? "center" : undefined
                            }}
                            className={classes.descContainer}
                        >
                            <Typography variant="h4" className={classes.title}>{t('rulesPolicy.title')}</Typography>
                            <Typography variant="subtitle1" className={classes.subtitle}>
                                {t('rulesPolicy.subtitle')}
                            </Typography>
                        </Grid>
                        <Grid item style={{padding: matchesSM ? '0 0 1rem 0' : matchesMD ? '1rem':'2rem'}}>
                            <div >
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.ageVerification')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            {t('rulesPolicy.1_1')}<br/><br/>
                                            {t('rulesPolicy.1_2')}<br/><br/>
                                            {t('rulesPolicy.1_3')}<br/><br/>
                                            {t('rulesPolicy.1_4')}<br/><br/>
                                            {t('rulesPolicy.1_5')}<br/>

                                            <ol>
                                                <li>{t('rulesPolicy.1_6')}
                                                    <a className={classes.link} href="mailto:Registrar@EYSC.club">{t('rulesPolicy.1Link')}</a><br/><br/>
                                                </li>
                                                <li>
                                                    {t('rulesPolicy.1_7')}<br/>
                                                    Everett Youth Soccer Club<br/>Attn: Registrar<br/>PO Box 2831<br/>Everett, WA 98213
                                                </li>
                                            </ol>

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.antiBully')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            {t('rulesPolicy.2_1')}<br/>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.2_2')}</h3>
                                            {t('rulesPolicy.2_3')}
                                            <a className={classes.link} rel="noreferrer"
                                               target="_blank" href="https://www.stopbullying.gov/what-is-bullying/index.html">
                                                https://www.stopbullying.gov/what-is-bullying/index.html</a>

                                            <h3 className={classes.subHeading}>{t('rulesPolicy.2_4')}</h3>
                                            {t('rulesPolicy.2_5')}
                                            <a className={classes.link} rel="noreferrer"
                                               target="_blank" href="https://www.stopbullying.gov/at-risk/warning-signs/">
                                                https://www.stopbullying.gov/at-risk/warning-signs/</a><br/><br/>

                                            <h3 className={classes.subHeading}>{t('rulesPolicy.2_6')}</h3>
                                            <ol style={{marginTop: 0}}>
                                                <li>{t('rulesPolicy.2_7')}</li>
                                                <li>{t('rulesPolicy.2_8')}</li>
                                                <li>{t('rulesPolicy.2_9')}</li>
                                            </ol>

                                            <h3 className={classes.subHeading}>{t('rulesPolicy.2_10')}</h3>
                                            <ol style={{marginTop: 0}}>
                                                <li>{t('rulesPolicy.2_11')}</li>
                                                <li>{t('rulesPolicy.2_12')}</li>
                                                <li>{t('rulesPolicy.2_13')}</li>
                                            </ol>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.communication')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.3_1')}</h3>
                                            {t('rulesPolicy.3_2')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.3_3')}</h3>
                                            {t('rulesPolicy.3_4')}
                                            <ul>
                                                <li>{t('rulesPolicy.3_5')}</li>
                                                <li>{t('rulesPolicy.3_6')}</li>
                                                <li>{t('rulesPolicy.3_7')}</li>
                                                <li>{t('rulesPolicy.3_8')}</li>
                                                <li>{t('rulesPolicy.3_9')}</li>
                                            </ul>
                                            {t('rulesPolicy.3_10')}<br/><br/>
                                            {t('rulesPolicy.3_11')}<br/><br/>
                                            {t('rulesPolicy.3_12')}<br/><br/>
                                            <i>{t('rulesPolicy.3_13')}</i>{t('rulesPolicy.3_14')}<br/><br/>
                                            <i>{t('rulesPolicy.3_15')}</i>{t('rulesPolicy.3_16')}<br/><br/>
                                            <i>{t('rulesPolicy.3_17')}</i>{t('rulesPolicy.3_18')}<br/><br/>
                                            {t('rulesPolicy.3_19')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.3_20')}</h3>
                                            {t('rulesPolicy.3_21')}<br/><br/>
                                            {t('rulesPolicy.3_22')}<br/><br/>
                                            {t('rulesPolicy.3_23')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.3_24')}</h3>
                                            {t('rulesPolicy.3_25')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.3_26')}</h3>
                                            {t('rulesPolicy.3_27')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.3_28')}</h3>
                                            {t('rulesPolicy.3_29')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.3_30')}</h3>
                                            {t('rulesPolicy.3_31')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.3_32')}</h3>
                                            {t('rulesPolicy.3_33')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.3_34')}</h3>
                                            {t('rulesPolicy.3_35')}<br/><br/>
                                            {t('rulesPolicy.3_36')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.inclusion')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            {t('rulesPolicy.4_1')}<br/><br/>
                                            {t('rulesPolicy.4_2')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.parentCode')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.5_1')}</h3>
                                            {t('rulesPolicy.5_2')}<br/><br/>
                                            {t('rulesPolicy.5_3')}<br/>
                                            <ol>
                                                <li>{t('rulesPolicy.5_4')}</li>
                                                <li>{t('rulesPolicy.5_5')}</li>
                                                <li>{t('rulesPolicy.5_6')}</li>
                                                <li>{t('rulesPolicy.5_7')}</li>
                                                <li>{t('rulesPolicy.5_8')}</li>
                                                <li>{t('rulesPolicy.5_9')}</li>
                                                <li>{t('rulesPolicy.5_10')}</li>
                                                <li>{t('rulesPolicy.5_11')}</li>
                                                <li>{t('rulesPolicy.5_12')}</li>
                                                <li>{t('rulesPolicy.5_13')}</li>
                                                <li>{t('rulesPolicy.5_14')}</li>
                                                <li>{t('rulesPolicy.5_15')}</li>
                                                <li>{t('rulesPolicy.5_16')}</li>
                                                <li>{t('rulesPolicy.5_17')}</li>
                                                <li>{t('rulesPolicy.5_18')}</li>
                                                <li>{t('rulesPolicy.5_19')}</li>
                                                <li>{t('rulesPolicy.5_20')}</li>
                                                <li>{t('rulesPolicy.5_21')}</li>
                                                <li>{t('rulesPolicy.5_22')}</li>
                                                <li>{t('rulesPolicy.5_23')}</li>
                                                <li>{t('rulesPolicy.5_24')}
                                                    <ul>
                                                        <li>{t('rulesPolicy.5_25')}</li>
                                                        <li>{t('rulesPolicy.5_26')}</li>
                                                        <li>{t('rulesPolicy.5_27')}</li>
                                                        <li>{t('rulesPolicy.5_28')}</li>
                                                        <li>{t('rulesPolicy.5_29')}</li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.playerCode')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.6_1')}</h3>
                                            {t('rulesPolicy.6_2')}<br/><br/>
                                            {t('rulesPolicy.6_3')}<br/>
                                            <ol>
                                                <li>{t('rulesPolicy.6_4')}</li>
                                                <li>{t('rulesPolicy.6_5')}</li>
                                                <li>{t('rulesPolicy.6_6')}</li>
                                                <li>{t('rulesPolicy.6_7')}</li>
                                                <li>{t('rulesPolicy.6_8')}</li>
                                                <li>{t('rulesPolicy.6_9')}</li>
                                                <li>{t('rulesPolicy.6_10')}</li>
                                            </ol>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.privacy')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            {t('rulesPolicy.7_1')}<br/><br/>
                                            <ul>
                                                <li>{t('rulesPolicy.7_2')}</li>
                                                <li>{t('rulesPolicy.7_3')}</li>
                                                <li>{t('rulesPolicy.7_4')}</li>
                                            </ul>
                                            {t('rulesPolicy.7_5')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.refund')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.8_1')}</h3>
                                            {t('rulesPolicy.8_2')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.8_3')}</h3>
                                            {t('rulesPolicy.8_4')}<br/><br/>
                                            {t('rulesPolicy.8_9')}<br/><br/>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.safeSport')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            {t('rulesPolicy.9_1')}<br/>
                                            <a className={classes.link} rel="noreferrer"
                                               target="_blank" href="http://www.washingtonyouthsoccer.org/resources/safesport/">
                                                SafeSport
                                            </a>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.weather')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            {t('rulesPolicy.10_1')}
                                            <a className={classes.link} rel="noreferrer"
                                               target="_blank" href="https://airnow.gov/">
                                                https://airnow.gov/
                                            </a><br/><br/>
                                            {t('rulesPolicy.10_2')}
                                            <a className={classes.link} rel="noreferrer"
                                               target="_blank" href="https://www.ncyouthsoccer.com/_files/ugd/8bc508_5f2accdbbd284e4591bf8c6b3e8f4da1.pdf">
                                                {t('rulesPolicy.10_3')}
                                            </a>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('rulesPolicy.tolerance')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            {t('rulesPolicy.11_1')}<br/><br/>
                                            {t('rulesPolicy.11_2')}<br/><br/>
                                            {t('rulesPolicy.11_3')}<br/><br/>
                                            <i>{t('rulesPolicy.11_4')}</i>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.11_5')}</h3>
                                            {t('rulesPolicy.11_6')}
                                            <ul>
                                                <li>{t('rulesPolicy.11_7')}</li>
                                                <li>{t('rulesPolicy.11_8')}</li>
                                                <li>{t('rulesPolicy.11_9')}</li>
                                                <li>{t('rulesPolicy.11_10')}</li>
                                            </ul>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.11_11')}</h3>
                                            {t('rulesPolicy.11_12')}
                                            <ul>
                                                <li>{t('rulesPolicy.11_13')}</li>
                                                <li>{t('rulesPolicy.11_14')}</li>
                                                <li>{t('rulesPolicy.11_15')}</li>
                                                <li>{t('rulesPolicy.11_16')}</li>
                                            </ul>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.11_17')}</h3>
                                            {t('rulesPolicy.11_18')}
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.11_19')}</h3>
                                            {t('rulesPolicy.11_20')}<br/><br/>
                                            {t('rulesPolicy.11_21')}<br/><br/>
                                            {t('rulesPolicy.11_22')}<br/><br/>
                                            {t('rulesPolicy.11_23')}<br/><br/>
                                            {t('rulesPolicy.11_24')}<br/><br/>
                                            {t('rulesPolicy.11_25')}
                                            <ul>
                                                <li>{t('rulesPolicy.11_26')}</li>
                                                <li>{t('rulesPolicy.11_27')}</li>
                                                <li>{t('rulesPolicy.11_28')}</li>
                                                <li>{t('rulesPolicy.11_29')}</li>
                                            </ul>
                                            <i>{t('rulesPolicy.11_30')}</i>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.11_31')}</h3>
                                            {t('rulesPolicy.11_32')}
                                            <ul>
                                                <li>{t('rulesPolicy.11_33')}</li>
                                                <li>{t('rulesPolicy.11_34')}</li>
                                                <li>{t('rulesPolicy.11_35')}</li>
                                                <li>{t('rulesPolicy.11_36')}</li>
                                                <li>{t('rulesPolicy.11_37')}</li>
                                                <li>{t('rulesPolicy.11_38')}</li>
                                            </ul>
                                            <h3 className={classes.subHeading}>{t('rulesPolicy.11_39')}</h3>
                                            {t('rulesPolicy.11_40')}<br/><br/>
                                            {t('rulesPolicy.11_41')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        style={{paddingLeft: matchesSM? '1rem': '2rem'}}
                    >
                        <Grid item>
                            <Typography variant="subtitle2" className={classes.subtitle}>
                                {t('rulesPolicy.download')}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction='row'
                            spacing={2}
                        >
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    variant='contained'
                                    color='primary'
                                    component={'a'}
                                    href={Pdf}
                                    rel='noreferrer noopener'
                                    target={'_blank'}
                                >
                                    {t('rulesPolicy.clubButton')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    variant='contained'
                                    color='primary'
                                    component={'a'}
                                    href={Pdf2}
                                    rel='noreferrer noopener'
                                    target={'_blank'}
                                >
                                    {t('rulesPolicy.bullyButton')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default RulesPolicy;