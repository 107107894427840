import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Team } from './Coaches.model';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme:Theme) => ({
    card: {
        width: '300px',
        height: '100%'
    },
    media: {
        height: '14rem',
        backgroundPosition: 'top',
    },
    heading: {
        backgroundColor: theme.palette.secondary.light,
        textAlign:'center',
        height: '6rem'
    },
}));

const CoachesCard = (props: Team) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const imageName = props.imagePath? props.imagePath : 'coach-placeholder';
    return (
        <React.Fragment>
            <Grid item>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={require (`../../../public/assets/${imageName}.jpg`)}
                            title="Coach Image"
                        />
                        <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                            {props.name}
                            <Typography variant="h6"  component="p">
                                {props.gender} {t(props.age)}
                            </Typography>
                        </Typography>
                        <CardContent>
                            <Typography variant="h6"  component="span">
                                {t('coachInfo.coach')}
                            </Typography>
                            <Typography variant="body1" component="p">
                                {props.coach}<br/><br/>
                            </Typography>
                            <Typography variant="h6"  component="span">
                                {props.coach2? t('coachInfo.coach') : props.asstCoach? t('coachInfo.asstCoach'): <br/>}
                            </Typography>
                            <Typography variant="body1" component="p">
                                {props.coach2? props.coach2 :props.asstCoach}<br/>
                                {props.asstCoach2? props.asstCoach2 :null}<br/>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </React.Fragment>
    );
};

export default CoachesCard;