import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Checkbox, CircularProgress, FormControlLabel, MenuItem, Snackbar, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FormValues } from './Coaches.model';
import axios from 'axios';
import { getFormParams } from './Coaches.util';

const useStyles = makeStyles((theme:Theme) => ({
    formBorder: {
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        padding: '3rem',
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            boxShadow: 'none',
            padding: 0,
            overflow: 'none'
        },
    },
    gridContainer: {
        padding: '5rem',
        marginTop: '5rem',
        [theme.breakpoints.down('md')]: {
            padding:'1rem'
        },
    },
    textField: {
        width:'30rem',
        height: '5rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    textFieldLong: {
        width: '100%',
        height: '5rem'
    },
    multiLineContainer: {
        paddingRight: '1rem'
    },
    multiLine:{
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
        "& .MuiFormLabel-root": {
            margin: "0.5rem 0 0 0.5rem"
        },
        paddingLeft: '10px'
    },
    gridDateContainer: {
        height: '6rem'
    },
    label: {
        "& .MuiFormLabel-root": {
            marginTop: "-1.3rem"
        }
    },
    button: {
        borderRadius: '50px',
        fontSize: '1rem',
        height: '45px',
        marginTop: '2rem',
        '&:hover':{
            backgroundColor: theme.palette.primary.light
        },
        [theme.breakpoints.down('sm')]: {
            height: '80px',
        },
    }
}));

const CoachApplication = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesXL = useMediaQuery(theme.breakpoints.down("xl"));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function getSeasons(): any[] {
        const currentDate = new Date();
        let month = currentDate.getMonth();
        const year = currentDate.getFullYear();
        let nextSpring = undefined;
        let nextFall = undefined;
        //If after March will want to register for next spring
        if (month > 2){
            nextSpring = {
                label: `Spring ${year + 1}`,
                value:`Spring ${year + 1}`,
            };
        }
        //If after September will want to register for next Fall
        if (month > 8){
            nextFall = {
                label: `Fall ${year + 1}`,
                value:`Fall ${year + 1}`,
            };
        }
        let seasonsDropdown = [
            {
                label: `Spring ${year}`,
                value:`Spring ${year}`,
            },
            {
                label: `Fall ${year}`,
                value:`Fall ${year}`,
            },
        ];
        if (nextSpring){
            seasonsDropdown = [
                ...seasonsDropdown,
                nextSpring
            ];
        }
        if (nextFall){
            seasonsDropdown = [
                ...seasonsDropdown,
                nextFall
            ];
        }
        return seasonsDropdown;
    }

    const seasons = getSeasons();
    const [formValues, setFormValues] = React.useState<any>(FormValues);
    const [loading, setLoading] = React.useState(false);
    const [alert, setAlert] = React.useState({
        open: false,
        message: '',
        backgroundColor: ''
    })

    const handleInputValidation = (event: any) => {
        const {name, value} = event.target;
        let input = formValues[name];
        let error = false;
        let errorMessage = '';
        //CHeck for required but empty
        if (input.required && !value.length){
            error = true;
            errorMessage = t('application.required');
        }
        //If not empty check for any formatting errors
        else if (!!value.length && input.regex){
            error = !input.regex.test(value);
            if (error){
                errorMessage = t(input.regexMessage);
            }
        }
        setFormValues({
            ...formValues,
            [name]:{
                ...formValues[name],
                value,
                error,
                errorMessage
            }
        })
    };

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        setFormValues({
            ...formValues,
            [name]:{
                ...formValues[name],
                value: checked
            }
        })
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        let input = formValues[name];
        let error = input.error;
        let errorMessage = input.errorMessage;

        if (input.error){
            //check to see if valid now
            if (!!value.length && input.regex){
                error = !input.regex.test(value);
                if (!error){
                    error = false;
                    errorMessage = '';
                }
            }else if (input.required && !!value.length){
                error = false;
                errorMessage = '';
            }
        }

        setFormValues({
            ...formValues,
            [name]:{
                ...formValues[name],
                value,
                error,
                errorMessage,
            }
        })
    };

    const saveApplicationData = () => {
        fetch('https://eysc-website-default-rtdb.firebaseio.com/coaching-applications.json', {
            method: 'POST',
            body: JSON.stringify(getFormParams(formValues, t)),
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch( err => {
            console.error("Was unable to save to Database ",err);
            }
        );
    };

    const emailApplicationData = () => {
        axios.get('https://us-central1-eysc-website.cloudfunctions.net/sendMail',
            {
                params: getFormParams(formValues, t),
            }
        )
            .then(() => {
                setLoading(false);
                // Clear form
                setFormValues(FormValues);
                setAlert({
                    open: true,
                    message: 'Message Sent Successfully',
                    backgroundColor: '#007500'
                });
            })
            .catch(() => {
                setLoading(false);
                setAlert({
                    open: true,
                    message: 'Message Failed to Send. Please Try again.',
                    backgroundColor: '#A30000'
                })
            });
    };

    const applicationSubmitHandler = (event: any) => {
        setLoading(true);
        event.preventDefault();
        saveApplicationData();
        emailApplicationData();
    };


    return <React.Fragment>
        <Snackbar open={alert.open}
                  message={alert.message}
                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                  onClose={() => setAlert({...alert, open: false})}
                  autoHideDuration={4000}
                  ContentProps={{style:{backgroundColor: alert.backgroundColor}}}/>
        <Grid container direction='column' className={classes.gridContainer}>
            <Grid item>
                <Typography variant='h4'>
                    {t('application.title')}
                </Typography>
                <Typography variant='subtitle1'>
                    {t('application.volunteers')}
                </Typography>
                <Typography variant='h6'>
                    <br/>
                    {t('application.application')}
                </Typography>
                <Typography variant='body1'>
                    {t('application.complete')}
                </Typography>
                <Typography variant='body1'>
                    <br/>
                    {t('application.important')}
                    <br/><br/>
                </Typography>
                {/*Start of Application Form*/}
                <form onSubmit={applicationSubmitHandler}>
                    <Grid container className={classes.formBorder}>
                    <Grid container direction='row' >
                        <Grid item>
                            <TextField
                                name='season'
                                select
                                onChange={handleFormChange}
                                value={formValues.season.value}
                                required
                                label={t('application.season')}
                                variant="filled"
                                helperText={t('application.seasonHelper').toString()}
                                error={formValues.season.error}
                                inputProps={{onBlur: handleInputValidation}}
                            >
                                {seasons.map((option) => (
                                    <MenuItem key={Math.floor(Math.random() * 1000)} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid >
                    {/*Section A*/}
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='h6'>
                                <br/>
                                {t('application.sectionA')}
                                <br/><br/>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent='space-evenly'>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                name='firstName'
                                value={formValues.firstName.value}
                                error={formValues.firstName.error}
                                onChange={handleFormChange}
                                required
                                label={t('application.firstName')}
                                className={classes.textField}
                                helperText={formValues.firstName.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                name='lastName'
                                value={formValues.lastName.value}
                                error={formValues.lastName.error}
                                onChange={handleFormChange}
                                required
                                label={t('application.lastName')}
                                className={classes.textField}
                                helperText={formValues.lastName.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row'>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                name='homePhone'
                                value={formValues.homePhone.value}
                                error={formValues.homePhone.error}
                                onChange={handleFormChange}
                                label={t('application.homePhone')}
                                className={classes.textField}
                                helperText={formValues.homePhone.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                name='cellPhone'
                                required
                                value={formValues.cellPhone.value}
                                error={formValues.cellPhone.error}
                                onChange={handleFormChange}
                                label={t('application.cellPhone')}
                                className={classes.textField}
                                helperText={formValues.cellPhone.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row'>
                        <Grid item xs={12}>
                            <TextField
                                name='address1'
                                required
                                value={formValues.address1.value}
                                error={formValues.address1.error}
                                onChange={handleFormChange}
                                label={t('application.address1')}
                                className={classes.textFieldLong}
                                helperText={formValues.address1.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row'>
                        <Grid item xs={12}>
                            <TextField
                                name='address2'
                                value={formValues.address2.value}
                                onChange={handleFormChange}
                                label={t('application.address2')}
                                className={classes.textFieldLong}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row'  justifyContent="space-between">
                        <Grid item lg={4} md={6} xs={12}>
                            <TextField
                                name='city'
                                required
                                value={formValues.city.value}
                                error={formValues.city.error}
                                onChange={handleFormChange}
                                label={t('application.city')}
                                style={{width:'20rem', height: '5rem'}}
                                helperText={formValues.city.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} xs={6}>
                            <TextField
                                name='state'
                                required
                                value={formValues.state.value}
                                error={formValues.state.error}
                                onChange={handleFormChange}
                                label={t('application.state')}
                                style={{width:'12rem', height: '5rem'}}
                                helperText={formValues.city.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} xs={6}>
                            <TextField
                                name='zipCode'
                                required
                                value={formValues.zipCode.value}
                                error={formValues.zipCode.error}
                                onChange={handleFormChange}
                                label={t('application.zip')}
                                style={{width:'12rem', height: '5rem'}}
                                helperText={formValues.zipCode.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} xs={6}>
                            <TextField
                                name='country'
                                value={formValues.country.value}
                                label={t('application.country')}
                                style={{width: matchesMD ? '12rem' :'8rem', height: '5rem'}}
                                helperText={t('application.mustBeUSA')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row'>
                        <Grid item xs={12}>
                            <TextField
                                name='email'
                                required
                                value={formValues.email.value}
                                error={formValues.email.error}
                                onChange={handleFormChange}
                                label={t('application.email')}
                                style={{width: matchesMD ? '100%' :'40rem', height: '5rem'}}
                                helperText={formValues.email.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                    </Grid>
                    {/*Section B*/}
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='h6'>
                                <br/>
                                {t('application.sectionB')}
                                <br/><br/>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent="space-between">
                        <Grid item xs={6} md={4} lg={3} className={classes.gridDateContainer}>
                            <TextField
                                name="safeSport"
                                label={t('application.safeSport')}
                                type="date"
                                required
                                error={formValues.safeSport.error}
                                onChange={handleFormChange}
                                defaultValue={formValues.safeSport.value}
                                helperText={formValues.safeSport.error ? formValues.safeSport.errorMessage: t('application.dateObtained')}
                                inputProps={{onBlur: handleInputValidation}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} className={classes.gridDateContainer}>
                            <TextField
                                className={classes.label}
                                name="concussion"
                                label={t('application.concussion')}
                                type="date"
                                required
                                error={formValues.concussion.error}
                                onChange={handleFormChange}
                                defaultValue={formValues.concussion.value}
                                helperText={formValues.concussion.error ? formValues.concussion.errorMessage: t('application.dateObtained')}
                                inputProps={{onBlur: handleInputValidation}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} className={classes.gridDateContainer}>
                            <TextField
                                className={classes.label}
                                name="cardiac"
                                label={t('application.cardiac')}
                                type="date"
                                required
                                error={formValues.cardiac.error}
                                onChange={handleFormChange}
                                defaultValue={formValues.cardiac.value}
                                helperText={formValues.cardiac.error ? formValues.cardiac.errorMessage: t('application.dateObtained')}
                                inputProps={{onBlur: handleInputValidation}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} className={classes.gridDateContainer}>
                            <TextField
                                className={classes.label}
                                name="backgroundCheck"
                                label={t('application.backgroundCheck')}
                                type="date"
                                required
                                error={formValues.backgroundCheck.error}
                                onChange={handleFormChange}
                                defaultValue={formValues.backgroundCheck.value}
                                helperText={formValues.backgroundCheck.error ? formValues.backgroundCheck.errorMessage: t('application.dateObtained')}
                                inputProps={{onBlur: handleInputValidation}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={3} className={classes.gridDateContainer}>
                            <TextField
                                name='levelCoaching'
                                value={formValues.levelCoaching.value}
                                onChange={handleFormChange}
                                label={t('application.highestLevelCoaching')}
                                style={{width:'20rem'}}
                            />
                        </Grid>
                    </Grid>

                    {/*Section C*/}
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='h6'>
                                <br/>
                                {t('application.sectionC')}
                                <br/>
                            </Typography>
                        </Grid>
                    </Grid>
                        <Grid container direction='row' >
                            <Grid item>
                                <Typography variant='body1'>
                                    {t('application.experience')}
                                    <br/><br/>
                                </Typography>
                            </Grid>
                        </Grid>

                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='body1'>
                                <strong>{matchesXL ? t('application.experienceValue') + ' 1': null}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent="space-evenly">
                        <Grid item xs={12} md={6} xl={2}>
                            <TextField
                                name='experienceYear1'
                                value={formValues.experienceYear1.value}
                                onChange={handleFormChange}
                                label={t('application.year')}
                                style={{width:'10rem', height: '5rem'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} xl={3}>
                            <TextField
                                name='experienceClub1'
                                value={formValues.experienceClub1.value}
                                onChange={handleFormChange}
                                label={t('application.club')}
                                style={{width: matchesMD? '80%':'15rem', height: '5rem'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} xl={3}>
                            <TextField
                                name='experienceAge1'
                                value={formValues.experienceAge1.value}
                                onChange={handleFormChange}
                                label={t('application.age')}
                                style={{width:'15rem', height: '5rem'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} xl={3}>
                            <TextField
                                name='experienceLeague1'
                                value={formValues.experienceLeague1.value}
                                onChange={handleFormChange}
                                label={t('application.league')}
                                style={{width:matchesMD? '80%':'15rem', height: '5rem'}}
                            />
                        </Grid>

                    </Grid>
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='body1'>
                                <strong>{matchesXL ? t('application.experienceValue') + ' 2': null}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent="space-evenly">
                            <Grid item xs={12} md={6} xl={2}>
                                <TextField
                                    name='experienceYear2'
                                    value={formValues.experienceYear2.value}
                                    onChange={handleFormChange}
                                    label={t('application.year')}
                                    style={{width:'10rem', height: '5rem'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <TextField
                                    name='experienceClub2'
                                    value={formValues.experienceClub2.value}
                                    onChange={handleFormChange}
                                    label={t('application.club')}
                                    style={{width: matchesMD? '80%':'15rem', height: '5rem'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <TextField
                                    name='experienceAge2'
                                    value={formValues.experienceAge2.value}
                                    onChange={handleFormChange}
                                    label={t('application.age')}
                                    style={{width:'15rem', height: '5rem'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <TextField
                                    name='experienceLeague2'
                                    value={formValues.experienceLeague2.value}
                                    onChange={handleFormChange}
                                    label={t('application.league')}
                                    style={{width:matchesMD? '80%':'15rem', height: '5rem'}}
                                />
                            </Grid>

                        </Grid>
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='body1'>
                                <strong>{matchesXL ? t('application.experienceValue') + ' 3': null}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent="space-evenly">
                            <Grid item xs={12} md={6} xl={2}>
                                <TextField
                                    name='experienceYear3'
                                    value={formValues.experienceYear3.value}
                                    onChange={handleFormChange}
                                    label={t('application.year')}
                                    style={{width:'10rem', height: '5rem'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <TextField
                                    name='experienceClub3'
                                    value={formValues.experienceClub3.value}
                                    onChange={handleFormChange}
                                    label={t('application.club')}
                                    style={{width: matchesMD? '80%':'15rem', height: '5rem'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <TextField
                                    name='experienceAge3'
                                    value={formValues.experienceAge3.value}
                                    onChange={handleFormChange}
                                    label={t('application.age')}
                                    style={{width:'15rem', height: '5rem'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <TextField
                                    name='experienceLeague3'
                                    value={formValues.experienceLeague3.value}
                                    onChange={handleFormChange}
                                    label={t('application.league')}
                                    style={{width:matchesMD? '80%':'15rem', height: '5rem'}}
                                />
                            </Grid>

                        </Grid>
                    {/*Section D*/}
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='h6'>
                                <br/>
                                {t('application.sectionD')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='body1'>
                                {t('application.referenceText')}
                                <br/><br/>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='body1'>
                                <strong>{matchesXL ? t('application.reference') + ' 1': null}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent="space-evenly">
                        <Grid item xs={12} lg={5} xl={4}>
                            <TextField
                                name='referenceName1'
                                required
                                error={formValues.referenceName1.error}
                                onChange={handleFormChange}
                                value={formValues.referenceName1.value}
                                label={t('application.referenceName')}
                                style={{width:'20rem', height: '5rem'}}
                                helperText={formValues.referenceName1.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                        <Grid item xs={12} lg={7} xl={5}>
                            <TextField
                                name='referenceAddress1'
                                required
                                value={formValues.referenceAddress1.value}
                                error={formValues.referenceAddress1.error}
                                onChange={handleFormChange}
                                label={t('application.referenceAddress')}
                                style={{width: matchesMD? '100%':'30rem', height: '5rem'}}
                                helperText={formValues.referenceAddress1.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>
                        <Grid item xs={12} xl={3}>
                            <TextField
                                name='referenceTelephone1'
                                required
                                value={formValues.referenceTelephone1.value}
                                error={formValues.referenceTelephone1.error}
                                onChange={handleFormChange}
                                label={t('application.referenceTelephone')}
                                style={{width:'15rem', height: '5rem'}}
                                helperText={formValues.referenceTelephone1.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                            />
                        </Grid>

                    </Grid>
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='body1'>
                                <strong>{matchesXL ? t('application.reference') + ' 2': null}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent="space-evenly">
                            <Grid item xs={12} lg={5} xl={4}>
                                <TextField
                                    name='referenceName2'
                                    required
                                    error={formValues.referenceName2.error}
                                    onChange={handleFormChange}
                                    value={formValues.referenceName2.value}
                                    label={t('application.referenceName')}
                                    style={{width:'20rem', height: '5rem'}}
                                    helperText={formValues.referenceName2.errorMessage}
                                    inputProps={{onBlur: handleInputValidation}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={7} xl={5}>
                                <TextField
                                    name='referenceAddress2'
                                    required
                                    value={formValues.referenceAddress2.value}
                                    error={formValues.referenceAddress2.error}
                                    onChange={handleFormChange}
                                    label={t('application.referenceAddress')}
                                    style={{width: matchesMD? '100%':'30rem', height: '5rem'}}
                                    helperText={formValues.referenceAddress2.errorMessage}
                                    inputProps={{onBlur: handleInputValidation}}
                                />
                            </Grid>
                            <Grid item xs={12} xl={3}>
                                <TextField
                                    name='referenceTelephone2'
                                    required
                                    value={formValues.referenceTelephone2.value}
                                    error={formValues.referenceTelephone2.error}
                                    onChange={handleFormChange}
                                    label={t('application.referenceTelephone')}
                                    style={{width:'15rem', height: '5rem'}}
                                    helperText={formValues.referenceTelephone2.errorMessage}
                                    inputProps={{onBlur: handleInputValidation}}
                                />
                            </Grid>

                        </Grid>
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='body1'>
                                <strong>{matchesXL ? t('application.reference') + ' 3': null}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent="space-evenly">
                            <Grid item xs={12} lg={5} xl={4}>
                                <TextField
                                    name='referenceName3'
                                    required
                                    error={formValues.referenceName3.error}
                                    onChange={handleFormChange}
                                    value={formValues.referenceName3.value}
                                    label={t('application.referenceName')}
                                    style={{width:'20rem', height: '5rem'}}
                                    helperText={formValues.referenceName3.errorMessage}
                                    inputProps={{onBlur: handleInputValidation}}
                                />
                            </Grid>
                            <Grid item xs={12} lg={7} xl={5}>
                                <TextField
                                    name='referenceAddress3'
                                    required
                                    value={formValues.referenceAddress3.value}
                                    error={formValues.referenceAddress3.error}
                                    onChange={handleFormChange}
                                    label={t('application.referenceAddress')}
                                    style={{width: matchesMD? '100%':'30rem', height: '5rem'}}
                                    helperText={formValues.referenceAddress3.errorMessage}
                                    inputProps={{onBlur: handleInputValidation}}
                                />
                            </Grid>
                            <Grid item xs={12} xl={3}>
                                <TextField
                                    name='referenceTelephone3'
                                    required
                                    value={formValues.referenceTelephone3.value}
                                    error={formValues.referenceTelephone3.error}
                                    onChange={handleFormChange}
                                    label={t('application.referenceTelephone')}
                                    style={{width:'15rem', height: '5rem'}}
                                    helperText={formValues.referenceTelephone3.errorMessage}
                                    inputProps={{onBlur: handleInputValidation}}
                                />
                            </Grid>

                        </Grid>

                    {/*Section E*/}
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='h6'>
                                <br/>
                                {t('application.sectionE')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className={classes.multiLineContainer}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{width: '90%'}}>
                                <br/><br/>
                                {t('application.question1')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.multiLine}
                                name="question1"
                                required
                                value={formValues.question1.value}
                                error={formValues.question1.error}
                                onChange={handleFormChange}
                                helperText={formValues.question1.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                                InputProps={{disableUnderline: true}}
                                multiline
                                minRows={4}
                                style={{width:'90%'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className={classes.multiLineContainer}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{width: '90%'}}>
                                <br/>
                                {t('application.question2')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.multiLine}
                                name="question2"
                                required
                                value={formValues.question2.value}
                                error={formValues.question2.error}
                                onChange={handleFormChange}
                                helperText={formValues.question2.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                                InputProps={{disableUnderline: true}}
                                multiline
                                minRows={4}
                                style={{width:'90%'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className={classes.multiLineContainer}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{width: '90%'}}>
                                <br/>
                                {t('application.question3')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.multiLine}
                                name="question3"
                                required
                                value={formValues.question3.value}
                                error={formValues.question3.error}
                                onChange={handleFormChange}
                                helperText={formValues.question3.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                                InputProps={{disableUnderline: true}}
                                multiline
                                minRows={4}
                                style={{width:'90%'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className={classes.multiLineContainer}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{width: '90%'}}>
                                <br/>
                                {t('application.question4')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.multiLine}
                                name="question4"
                                required
                                value={formValues.question4.value}
                                error={formValues.question4.error}
                                onChange={handleFormChange}
                                helperText={formValues.question4.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                                InputProps={{disableUnderline: true}}
                                multiline
                                minRows={4}
                                style={{width:'90%'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className={classes.multiLineContainer}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{width: '90%'}}>
                                <br/>
                                {t('application.question5')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.multiLine}
                                name="question5"
                                required
                                value={formValues.question5.value}
                                error={formValues.question5.error}
                                onChange={handleFormChange}
                                helperText={formValues.question5.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                                InputProps={{disableUnderline: true}}
                                multiline
                                minRows={4}
                                style={{width:'90%'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className={classes.multiLineContainer}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{width: '90%'}}>
                                <br/>
                                {t('application.question6')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.multiLine}
                                name="question6"
                                required
                                value={formValues.question6.value}
                                error={formValues.question6.error}
                                onChange={handleFormChange}
                                helperText={formValues.question6.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                                InputProps={{disableUnderline: true}}
                                multiline
                                minRows={4}
                                style={{width:'90%'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className={classes.multiLineContainer}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{width: '90%'}}>
                                <br/>
                                {t('application.question7')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.multiLine}
                                name="question7"
                                required
                                value={formValues.question7.value}
                                error={formValues.question7.error}
                                onChange={handleFormChange}
                                helperText={formValues.question7.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                                InputProps={{disableUnderline: true}}
                                multiline
                                minRows={4}
                                style={{width:'90%'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className={classes.multiLineContainer}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{width: '90%'}}>
                                <br/>
                                {t('application.question8')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.multiLine}
                                name="question8"
                                required
                                value={formValues.question8.value}
                                error={formValues.question8.error}
                                onChange={handleFormChange}
                                helperText={formValues.question8.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                                InputProps={{disableUnderline: true}}
                                multiline
                                minRows={4}
                                style={{width:'90%'}}
                            />
                        </Grid>
                    </Grid>

                    {/*Section Agreement*/}
                    <Grid container direction='row' >
                        <Grid item>
                            <Typography variant='h6'>
                                <br/>
                                {t('application.agreementTitle')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' >
                        <Grid item xs={12}>
                            <TextField
                                name="agreement"
                                value={t('application.agreement')}
                                multiline
                                maxRows={8}
                                disabled
                                style={{width: matchesMD ? '100%' :'80%'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' >
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formValues.agree.value}
                                        onChange={handleCheck}
                                        required
                                        name="agree"
                                        color="primary"
                                    />
                                }
                                label={t('application.agree')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent='space-around'>
                        <Grid item lg={4} md={6} sm={8} xs={10}>
                            <TextField
                                name='signature'
                                required
                                value={formValues.signature.value}
                                error={formValues.signature.error}
                                onChange={handleFormChange}
                                helperText={formValues.signature.errorMessage}
                                inputProps={{onBlur: handleInputValidation}}
                                label={t('application.signature')}
                                style={{width:'20rem', paddingRight: matchesSM ? 0 :'5rem'}}
                            />
                        </Grid>
                        <Grid item lg={8} md={6} sm={4} xs={2}>
                            <TextField
                                name='date'
                                value={formValues.date.value}
                                label={t('application.date')}
                                disabled
                                style={{width:'6rem', paddingRight: matchesSM ? 0 :'5rem'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row' >
                        <Grid item>
                            <Button
                                className={classes.button}
                                variant='contained'
                                color='primary'
                                type='submit'
                                disabled={loading}
                            >
                                {t('application.submit')}
                            </Button>
                        </Grid>
                        <Grid item style={{marginLeft: '10px'}}>
                            <br/><br/>
                            {loading && <CircularProgress />}
                        </Grid>
                    </Grid>
                </Grid>
                </form>
            </Grid>

        </Grid>
    </React.Fragment>
};

export default CoachApplication;