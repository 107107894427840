import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        marginTop: "3rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    image: {
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        [theme.breakpoints.down('sm')]: {
            height: '14rem'
        }
    },
    descContainer: {
        maxWidth: '40rem',
    },
    subtitle: {
        marginBottom: "1em",
        lineHeight: "1.5rem"
    },
    title: {
        margin: "1em 0"
    },
    accordionOuterContainer: {
        marginTop: '2rem'
    },
    accordionContainer: {
        backgroundColor: theme.palette.primary.main,
    },
    heading: {
        ...theme.typography.tab,
        color: 'white',
        fontSize: '1.5rem'
    },
    arrow: {
        color: 'white',
        transform: 'scale(1.3)',
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
    strongText: {
        fontSize: '1.2rem',
        fontWeight: 800,
        margin: '1rem 0',
    },
    bottomNote: {
        ...theme.typography.tab,
        fontSize: '1rem'
    },
    button: {
        borderRadius: '50px',
        fontFamily: 'Indie Flower',
        textTransform: 'none',
        fontWeight:'bold',
        fontSize: '1.5rem',
        height: '45px',
        margin: '1rem',
        [theme.breakpoints.down('sm')]: {
        height: '80px',
    },
},
}));

const MicrosInfo = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="column">
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid item style={{ marginRight: matchesSM ? 0 : "5em" }}>
                            <img
                                className={classes.image}
                                alt="micro registration pic"
                                src={require('../../assets/registration-micro.jpeg')}
                            />
                        </Grid>
                        <Grid
                            item
                            style={{
                                textAlign: matchesSM ? "center" : undefined
                            }}
                            className={classes.descContainer}
                        >
                            <Typography variant="h4" className={classes.title}>{t('micros.title')}</Typography>
                            <Typography variant="subtitle1" className={classes.subtitle}>
                                {t('micros.text')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid
                            item
                            className={classes.descContainer}
                        >
                            <div >
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>{t('micros.fallHeader')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            <ul>
                                                <li>{t('micros.fallB1')}</li>
                                                <li>{t('micros.fallB2')}</li>
                                                <li>{t('micros.fallB3')}</li>
                                                <li>{t('micros.fallB4')}</li>
                                                <li>{t('micros.fallB5')}</li>
                                            </ul>
                                            <strong>{t('micros.refunds')}</strong><br/><br/>
                                            <strong>{t('micros.ageGroup')}
                                                <Link className={classes.link} to='/resources/faq/'>{t('micros.link')}</Link>
                                            </strong>
                                            <ul>
                                                <li>{t('micros.fallB6')}</li>
                                                <li>{t('micros.fallB7')}</li>
                                                <li>{t('micros.fallB8')}</li>
                                                <li>{t('micros.fallB9')}</li>
                                            </ul>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>{t('micros.springHeader')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            {t('micros.springDetails')}
                                            <ul>
                                                <li>{t('micros.springB1')}</li>
                                                <li>{t('micros.springB2')}</li>
                                                <li>{t('micros.springB3')}</li>
                                            </ul>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid
                            item
                            style={{
                                textAlign: "center"
                            }}
                        >
                            <Typography variant="subtitle1" className={classes.bottomNote}>
                                {t('micros.volunteers')}
                                <a className={classes.link} href="mailto:micros@EYSC.club">micros@EYSC.club</a><br/>
                                <div className={classes.strongText}>{t('micros.birthCert')} </div>
                                <div>
                                    <Button
                                        component={Link}
                                        to="/registration/"
                                        className={classes.button}
                                        variant='contained'
                                        color='secondary'
                                        // onClick={() => {
                                        //     props.setValue(1);
                                        //     props.setSelectedIndex(2);
                                        // }}
                                    >
                                        {t('micros.register')}
                                    </Button>
                                </div>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default MicrosInfo;