import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        marginTop: "3rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    image: {
        width: '28rem',
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        [theme.breakpoints.down('sm')]: {
            width: '18rem',
        }
    },
    descContainer: {
        maxWidth: '45rem',
    },
    subtitle: {
        marginBottom: "1em",
        lineHeight: "1.5rem"
    },
    title: {
        margin: "1em 0"
    },
    accordionOuterContainer: {
        marginTop: '2rem'
    },
    accordionContainer: {
        backgroundColor: theme.palette.primary.main,
    },
    heading: {
        ...theme.typography.tab,
        color: 'white',
        fontSize: '1.5rem'
    },
    arrow: {
        color: 'white',
        transform: 'scale(1.3)',
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
    strongText: {
        fontSize: '1.2rem',
        fontWeight: 800,
        marginTop: '1.5rem',
    },
    bottomNote: {
        ...theme.typography.tab,
        fontSize: '1rem',
    },
    button: {
        borderRadius: '50px',
        fontFamily: 'Indie Flower',
        textTransform: 'none',
        fontWeight:'bold',
        fontSize: '1.5rem',
        height: '45px',
        margin: '1rem',
        [theme.breakpoints.down('sm')]: {
            height: '80px',
        },
    },
}));

const PremierInfo = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const currentYear = new Date().getFullYear();
    const nextYear = (currentYear %100)+1;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="column">
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid item style={{ marginRight: matchesSM ? 0 : "5em" }}>
                            <img
                                className={classes.image}
                                alt="select registration pic"
                                src={require('../../assets/home-tryouts.jpeg')}
                            />
                        </Grid>
                        <Grid
                            item
                            style={{
                                textAlign: matchesSM ? "center" : undefined
                            }}
                            className={classes.descContainer}
                        >
                            <Typography variant="h4" className={classes.title}>{t('premier.title')}</Typography>
                            <Typography variant="subtitle1" className={classes.subtitle}>
                                {t('premier.text')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid
                            item
                            className={classes.descContainer}
                        >
                            <div >
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >

                                        <Typography className={classes.heading}>
                                            {t('premier.header1')}<br/>
                                            {`${t('premier.header1b')} (${currentYear}/${nextYear})`}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            <ul>
                                                <li>{t('premier.h1b1')}</li>
                                                <li>{t('premier.h1b2')}</li>
                                                <li>{t('premier.h1b3')}</li>
                                                <li>{t('premier.h1b4')}</li>
                                                <li>{t('premier.h1b5')}</li>
                                                {/*<li>{t('premier.h1b6')}</li>*/}
                                                <li>{t('premier.h1b7')}</li>
                                                <li>{t('premier.h1b8')}</li>
                                            </ul>
                                            {t('premier.h1Includes')}<br/>
                                            {t('premier.h1Includes2')}<br/><br/>
                                            {t('premier.h1Optional')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('premier.header2')}<br/>
                                            {`${t('premier.header1b')} (${currentYear}/${nextYear})`}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            <ul>
                                                <li>{t('premier.h2b1')}</li>
                                                <li>{t('premier.h2b2')}</li>
                                                <li>{t('premier.h2b3')}</li>
                                                <li>{t('premier.h2b4')}</li>
                                                {/*<li>{t('premier.h2b5')}</li>*/}
                                                <li>{t('premier.h2b6')}</li>
                                                <li>{t('premier.h2b7')}</li>
                                            </ul>
                                            {t('premier.h2Includes')}<br/>
                                            {t('premier.h2Includes2')}<br/><br/>
                                            {t('premier.h2Note')}<br/>
                                            {t('premier.h2Note2')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className={classes.accordionOuterContainer}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className={classes.arrow}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordionContainer}
                                    >
                                        <Typography className={classes.heading}>
                                            {t('premier.header3')}<br/>
                                            {`${t('premier.header1b')} (${currentYear}/${nextYear})`}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'span'}>
                                            <ul>
                                                <li>{t('premier.h3b1')}</li>
                                                <li>{t('premier.h3b2')}</li>
                                                <li>{t('premier.h3b3')}</li>
                                                <li>{t('premier.h3b4')}</li>
                                                <li>{t('premier.h3b5')}</li>
                                                {/*<li>{t('premier.h3b6')}</li>*/}
                                                <li>{t('premier.h3b7')}</li>
                                                <li>{t('premier.h3b8')}</li>
                                            </ul>
                                            {t('premier.h3Includes')}<br/>
                                            {t('premier.h3Includes2')}<br/><br/>
                                            {t('premier.h3Note')}<br/>
                                            {t('premier.h3Note2')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.gridContainer}
                    >
                        <Grid
                            item
                            style={{
                                textAlign: "center",
                                maxWidth: '80%'
                            }}
                        >
                            <Typography variant="subtitle1" className={classes.bottomNote}>
                                <div className={classes.strongText}>{t('premier.feesHeading')}</div>
                                {t('premier.feesDesc')}<br/><br/>

                                {t('premier.assistance')}
                                <a className={classes.link} href="mailto:Scholarships@EYSC.club">Scholarships@EYSC.club</a><br/>
                                <div className={classes.strongText}>{t('premier.birthCert')} </div>
                                <div>
                                    <Button
                                        component={'a'}
                                        href='https://go.teamsnap.com/forms/418882'
                                        rel='noreferrer noopener'
                                        target={'_blank'}
                                        className={classes.button}
                                        variant='contained'
                                        color='secondary'
                                    >
                                        {t('premier.register')}
                                    </Button>
                                </div>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default PremierInfo;