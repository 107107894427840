import React, {useEffect, useMemo, useState} from 'react';
import { GoogleMap, InfoWindow, LoadScript, MarkerF } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import { GameFieldLocations, GeoLocation } from './FieldsLocations.model';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme:Theme) => ({
    gridContainer: {
        margin: "3rem",
        [theme.breakpoints.down("sm")]: {
            padding: '25px',
            width: '22rem',
            margin: "1rem",
        }
    },
    image: {
        boxShadow: '0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15)',
        [theme.breakpoints.down('md')]: {
            height: '25rem',
            width: '30rem'
        },
        [theme.breakpoints.down('sm')]: {
            height: '25rem',
            width: '20rem'
        },
        marginBottom:'3rem',
        height: "70vh",
        width: "100%"
    },
    descContainer: {
        width: '35rem',
        [theme.breakpoints.down('md')]: {
            width: '25rem'
        }
    },
    subtitle: {
        marginBottom: "1em",
        lineHeight: "1.5rem"
    },
    title: {
        margin: "1em 0"
    },
}));

const GameFields = () => {
    const classes = useStyles();
    const locations = GameFieldLocations;
    const theme = useTheme();
    const { t } = useTranslation();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const mapStyles = {
        height: "100%",
        width: "100%"};

    const center = useMemo(() => ({ lat: 47.940130, lng: -122.224010 }), []);

    const [ selected, setSelected ] = useState<GeoLocation | null>(null);

    const onSelect = (item:any) => {
        setSelected(item);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <Grid container direction="column">
                <Grid item>
                    {" "}
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        className={classes.gridContainer}
                    >
                        <Grid
                            item
                            style={{
                                textAlign: matchesSM ? "center" : undefined
                            }}
                            className={classes.descContainer}
                        >
                            <Typography variant="h4" className={classes.title}>{t('fields.gameTitle')}</Typography>
                            <Typography variant="subtitle1" className={classes.subtitle}>
                                {t('fields.gameText')}<br/>
                                {t('fields.gameText2')}
                            </Typography>
                        </Grid>
                        <Grid item style={{ width: "50rem", margin:matchesSM ? 0 : "2rem"  }}>
                            <div className={classes.image}>
                                <LoadScript
                                    googleMapsApiKey='AIzaSyB-xpvPH_kwWS-Vw37VnligJvDaxrt5iNQ'>
                                    <GoogleMap
                                        mapContainerStyle={mapStyles}
                                        zoom={9}
                                        center={center}
                                    >
                                        {
                                            locations.map(item => {
                                                return (
                                                    <MarkerF key={item.name}
                                                             position={item.location}
                                                             onClick={() => onSelect(item)}
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            selected?.location &&
                                            (
                                                <InfoWindow
                                                    position={selected.location}
                                                    onCloseClick={() => setSelected(null)}
                                                >
                                                    <Typography variant="body2" component="div">
                                                        <strong>{selected.name}</strong><br/>
                                                        {selected.location.address}<br/>
                                                        <a
                                                            href={selected.location.href}
                                                            target="_blank"
                                                            rel='noreferrer noopener'
                                                            className="underline hover:text-blue-750">
                                                            {t('fields.directions')}
                                                        </a>
                                                    </Typography>
                                                </InfoWindow>
                                            )
                                        }
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default GameFields;