import React, { useEffect, useMemo, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import MenuComponent from './MenuComponent';
import Pdf from '../../assets/2020_EYSC_training_pre_approval_request_form.pdf'
import { NavProps, Routes } from './Navigation.model';
import NavListItem from './NavListItem';

function ElevationScroll(props:any) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const useStyles = makeStyles((theme:Theme) => ({
    toolbarMargin: {
        ...theme.mixins.toolbar,
        marginBottom: '2.5em'
    },
    logoContainer: {
        [theme.breakpoints.down('lg')]: {
            margin: 'auto',
        },
    },
    logo: {
        height: '7em',
        top: '2em',
        position: 'relative',
        [theme.breakpoints.down('lg')]: {
            height: '6em',
        },
    },
    tabContainer: {
        marginLeft: 'auto',
        flexGrow: 1,
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
        },
    },
    tab: {
        ...theme.typography.tab,
        minWidth: 10,
        marginLeft: '1rem',
        fontSize: '1rem',
    },
    button: {
        borderRadius: '50px',
        fontFamily: 'Indie Flower',
        fontWeight:'bold',
        fontSize: '1rem',
        height: '45px',
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
    menu: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    menuItem: {
        ...theme.typography.tab,
        opacity: 0.7,
        fontWeight: 600,
        '&:hover': {
            opacity: 1,
            color: theme.palette.secondary.main,
        }
    },
    drawerIconContainer: {
        '&hover': {
            backgroundColor: 'transparent'
        },
        color: 'white',
        marginRight: 'auto',
    },
    drawerIcon: {
        height: '50px',
        width: '50px'
    },
    drawer: {
        backgroundColor: theme.palette.primary.main,
    },
    drawerItem: {
        ...theme.typography.tab,
        color: 'white',
        opacity: 0.7,
        fontWeight: 600,
        '&:hover': {
            opacity: 1,
            color: theme.palette.secondary.main,
        }
    },
    drawerItemRegister: {
        backgroundColor: theme.palette.secondary.main,
    },
    drawerItemSelected: {
        ...theme.typography.tab,
        opacity: 1,
        fontWeight: 600,
        color: theme.palette.secondary.main,
    },
    appBar: {
        [theme.breakpoints.down('lg')]: {
            zIndex: theme.zIndex.modal + 1
        },
    }
}));

export default function NavigationHeader(props: NavProps) {
    const classes = useStyles();
    const theme = useTheme();
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const matches = useMediaQuery(theme.breakpoints.down('lg'))
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [tabSubIndex, setTabSubIndex] = useState(0);
    const [anchorProgramsEl, setAnchorProgramsEl] = useState(null);
    const [anchorFieldsEl, setAnchorFieldsEl] = useState(null);
    const [anchorCoachesEl, setAnchorCoachesEl] = useState(null);
    const [anchorResourcesEl, setAnchorResourcesEl] = useState(null);
    const [anchorAboutEl, setAnchorAboutEl] = useState(null);
    const [openProgramMenu, setOpenProgramMenu] = useState(false);
    const [openFieldsMenu, setOpenFieldsMenu] = useState(false);
    const [openCoachesMenu, setOpenCoachesMenu] = useState(false);
    const [openResourcesMenu, setOpenResourcesMenu] = useState(false);
    const [openAboutMenu, setOpenAboutMenu] = useState(false);
    const [selectedProgramIndex, setSelectedProgramIndex] = useState(0);
    const [selectedFieldsIndex, setSelectedFieldsIndex] = useState(0);
    const [selectedCoachesIndex, setSelectedCoachesIndex] = useState(0);
    const [selectedResourcesIndex, setSelectedResourcesIndex] = useState(0);


    const handleTabChange = (event: any, newValue: number) => {
        props.setTabIndex(newValue);
    };

    const handleProgramClick = (event: any) => {
        setAnchorProgramsEl(event.currentTarget);
        setOpenProgramMenu(true);
    };

    const handleFieldsClick = (event: any) => {
        setAnchorFieldsEl(event.currentTarget);
        setOpenFieldsMenu(true);
    };

    const handleCoachesClick = (event: any) => {
        setAnchorCoachesEl(event.currentTarget);
        setOpenCoachesMenu(true);
    };

    const handleResourcesClick = (event: any) => {
        setAnchorResourcesEl(event.currentTarget);
        setOpenResourcesMenu(true);
    };

    const handleAboutClick = (event: any) => {
        setAnchorAboutEl(event.currentTarget);
        setOpenAboutMenu(true);
    };

    const programMenuOptions: Routes[] = useMemo(() => [
        {name: t('navbar.philosophy'), link: '/programs/philosophy/', tabIndex: 1, subTabIndex: 0,
            setSelectedIndex: setSelectedProgramIndex, selectedIndex: selectedProgramIndex},
        {name: t('navbar.microsInfo'), link: '/programs/micros-info-fees/', tabIndex: 1, subTabIndex: 1,
            setSelectedIndex: setSelectedProgramIndex, selectedIndex: selectedProgramIndex},
        {name: t('navbar.modsInfo'), link: '/programs/mods-info-fees/', tabIndex: 1, subTabIndex: 2,
            setSelectedIndex: setSelectedProgramIndex, selectedIndex: selectedProgramIndex},
        {name: t('navbar.selectInfo'), link: '/programs/select-info-fees/', tabIndex: 1, subTabIndex: 3,
            setSelectedIndex: setSelectedProgramIndex, selectedIndex: selectedProgramIndex},
        {name: t('navbar.premierInfo'), link: '/programs/premier-info-fees/', tabIndex: 1, subTabIndex: 4,
            setSelectedIndex: setSelectedProgramIndex, selectedIndex: selectedProgramIndex},
        {name: t('navbar.tournamentsInfo'), link: '/programs/tournaments-indoor-play/', tabIndex: 1, subTabIndex: 6,
            setSelectedIndex: setSelectedProgramIndex, selectedIndex: selectedProgramIndex},
    ], [t, selectedProgramIndex, setSelectedProgramIndex ]);


    const fieldMenuOptions: Routes[] = useMemo(() => [
        {name: t('navbar.practiceFields'), link: '/practice-fields/', tabIndex: 3, subTabIndex: 0,
            setSelectedIndex: setSelectedFieldsIndex, selectedIndex: selectedFieldsIndex},
        {name: t('navbar.gameFields'), link: '/game-fields/', tabIndex: 3, subTabIndex: 1,
            setSelectedIndex: setSelectedFieldsIndex, selectedIndex: selectedFieldsIndex},
    ], [t, selectedFieldsIndex, setSelectedFieldsIndex ]);

    const coachMenuOptions: Routes[] = useMemo(() => [
        {name: t('navbar.coachInfo'), link: '/coach-info/', tabIndex: 4, subTabIndex: 0,
            setSelectedIndex: setSelectedCoachesIndex, selectedIndex: selectedCoachesIndex},
        {name: t('navbar.ourCoaches'), link: '/coach-bios/', tabIndex: 4, subTabIndex: 1,
            setSelectedIndex: setSelectedCoachesIndex, selectedIndex: selectedCoachesIndex},
        {name: t('navbar.coachApplication'), link: '/coach-info/coach-application/', tabIndex: 4, subTabIndex: 2,
            setSelectedIndex: setSelectedCoachesIndex, selectedIndex: selectedCoachesIndex},
    ], [t, selectedCoachesIndex, setSelectedCoachesIndex ]);

    const resourceMenuOptions: Routes[] = useMemo(() => [
        {name: t('navbar.parentResources'), link: '/resources/parent-resources/', tabIndex: 5, subTabIndex: 0,
            setSelectedIndex: setSelectedResourcesIndex, selectedIndex: selectedResourcesIndex},
        {name: t('navbar.faq'), link: '/resources/faq/', tabIndex: 5, subTabIndex: 1,
            setSelectedIndex: setSelectedResourcesIndex, selectedIndex: selectedResourcesIndex},
        {name: t('navbar.rulesPolicy'), link: '/resources/rules-policies/', tabIndex: 5, subTabIndex: 2,
            setSelectedIndex: setSelectedResourcesIndex, selectedIndex: selectedResourcesIndex},
        {name: t('navbar.referees'), href: { pathname: "https://www.ncrefs.org/" },tabIndex: 5, subTabIndex: 3,
            setSelectedIndex: setSelectedResourcesIndex, selectedIndex: selectedResourcesIndex},
        {name: t('navbar.refereeIncentive'), link: '/resources/referee-incentive/', tabIndex: 5, subTabIndex: 4,
            setSelectedIndex: setSelectedResourcesIndex, selectedIndex: selectedResourcesIndex},
        {name: t('navbar.coachesTraining'), href: Pdf, tabIndex: 5, subTabIndex: 5,
            setSelectedIndex: setSelectedResourcesIndex, selectedIndex: selectedResourcesIndex},
    ], [t, selectedResourcesIndex, setSelectedResourcesIndex ]);

    const aboutMenuOptions: Routes[] = useMemo(() => [
        {name: t('navbar.about'), link: '/about/', tabIndex: 6, subTabIndex: 0,
            setSelectedIndex: props.setSelectedAboutIndex, selectedIndex: props.selectedAboutIndex},
        {name: t('navbar.ourBoard'), link: '/about/our-board/', tabIndex: 6, subTabIndex: 1,
            setSelectedIndex: props.setSelectedAboutIndex, selectedIndex: props.selectedAboutIndex},
        {name: t('navbar.handInHand'), link: '/about/hand-in-hand/', tabIndex: 6, subTabIndex: 2,
            setSelectedIndex: props.setSelectedAboutIndex, selectedIndex: props.selectedAboutIndex},
        {name: t('navbar.partners'), link: '/about/community-partners/', tabIndex: 6, subTabIndex: 3,
            setSelectedIndex: props.setSelectedAboutIndex, selectedIndex: props.selectedAboutIndex},
        {name: t('navbar.sponsors'), link: '/about/sponsors/', tabIndex: 6, subTabIndex: 4,
            setSelectedIndex: props.setSelectedAboutIndex, selectedIndex: props.selectedAboutIndex},
        {name: t('navbar.contact'), link: '/about/contact-eysc/', tabIndex: 6, subTabIndex: 5,
            setSelectedIndex: props.setSelectedAboutIndex, selectedIndex: props.selectedAboutIndex},
    ], [t, props.selectedAboutIndex, props.setSelectedAboutIndex ]);

    const routes: Routes[] = useMemo(() => [
        {name: t('navbar.home'), link:'/', tabIndex: 0, subTabIndex: 0},
        {name: t('navbar.programs'), tabIndex: 1, areaOwns:anchorProgramsEl ? 'programs-menu' : undefined,
            areaHasPopup:anchorProgramsEl ? true : undefined, onMouseOver: (event: any) => handleProgramClick(event),
            subRoutes: programMenuOptions},
        {name: t('navbar.registration'), link:'/registration/', tabIndex: 2},
        {name: t('navbar.fields'), tabIndex: 3, areaOwns:anchorFieldsEl ? 'fields-menu' : undefined,
            areaHasPopup:anchorFieldsEl ? true : undefined, onMouseOver: (event: any) => handleFieldsClick(event),
            subRoutes: fieldMenuOptions},
        {name: t('navbar.coaches'), tabIndex: 4, areaOwns:anchorCoachesEl ? 'coaches-menu' : undefined,
            areaHasPopup:anchorCoachesEl ? true : undefined, onMouseOver: (event: any) => handleCoachesClick(event),
            subRoutes: coachMenuOptions},
        {name: t('navbar.resources'), tabIndex: 5, areaOwns:anchorResourcesEl ? 'resources-menu' : undefined,
            areaHasPopup:anchorResourcesEl ? true : undefined, onMouseOver: (event: any) => handleResourcesClick(event),
            subRoutes: resourceMenuOptions},
        {name: t('navbar.about-us'), tabIndex: 6, areaOwns:anchorAboutEl ? 'about-menu' : undefined,
            areaHasPopup:anchorAboutEl ? true : undefined, onMouseOver: (event: any) => handleAboutClick(event),
            subRoutes: aboutMenuOptions},
    ], [t, aboutMenuOptions, anchorAboutEl, resourceMenuOptions, anchorResourcesEl, coachMenuOptions,
        anchorCoachesEl, fieldMenuOptions, anchorFieldsEl, programMenuOptions, anchorProgramsEl ]);

    useEffect(() => {
        //Used to highlight correct selected tab on refresh
        [
            ...programMenuOptions,
            ...fieldMenuOptions,
            ...coachMenuOptions,
            ...resourceMenuOptions,
            ...aboutMenuOptions,
            ...routes].forEach(route => {
            switch (window.location.pathname) {
                case `${route.link}`:
                    if (props.tabIndex !== route.tabIndex) {
                        props.setTabIndex(route.tabIndex);
                        if (
                            route.subTabIndex
                        ) {
                            switch (props.tabIndex){
                                case 1:
                                    setSelectedProgramIndex(route.subTabIndex);
                                    setOpenProgramMenu(false);
                                    break;
                                case 3:
                                    setSelectedFieldsIndex(route.subTabIndex);
                                    setOpenFieldsMenu(false);
                                    break;
                                case 4:
                                    setSelectedCoachesIndex(route.subTabIndex);
                                    setOpenCoachesMenu(false);
                                    break;
                                case 5:
                                    setSelectedResourcesIndex(route.subTabIndex);
                                    setOpenResourcesMenu(false);
                                    break;
                                case 6:
                                    props.setSelectedAboutIndex(route.subTabIndex);
                                    setOpenAboutMenu(false);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        });
    }, [props, programMenuOptions, fieldMenuOptions, coachMenuOptions, resourceMenuOptions, aboutMenuOptions, routes]);


    const tabs = (
        <React.Fragment>
            <Tabs value={props.tabIndex}
                  onChange={handleTabChange}
                  className={classes.tabContainer}>
                {routes.map((route: Routes, index) => (
                    <Tab
                        key={`${route.name}${index}`}
                        component={Link} to={route.link? route.link : ''}
                        aria-owns={route.areaOwns}
                        aria-haspopup={route.areaHasPopup}
                        className={classes.tab}
                        onMouseOver={route.onMouseOver}
                        label={route.name} />
                ))}
            </Tabs>

            <MenuComponent
                id='programs-menu'
                menuOptions={programMenuOptions}
                onSetTab={props.setTabIndex}
                tabIndex={1}
                selectedTab={props.tabIndex}
                setAnchorEl={setAnchorProgramsEl}
                anchorEl={anchorProgramsEl}
                setOpenMenu={setOpenProgramMenu}
                openMenu={openProgramMenu}
                setSelectedIndex={setSelectedProgramIndex}
                selectedIndex={selectedProgramIndex}
            />

            <MenuComponent
                id='fields-menu'
                menuOptions={fieldMenuOptions}
                onSetTab={props.setTabIndex}
                tabIndex={3}
                selectedTab={props.tabIndex}
                setAnchorEl={setAnchorFieldsEl}
                anchorEl={anchorFieldsEl}
                setOpenMenu={setOpenFieldsMenu}
                openMenu={openFieldsMenu}
                setSelectedIndex={setSelectedFieldsIndex}
                selectedIndex={selectedFieldsIndex}
            />

            <MenuComponent
                id='coaches-menu'
                menuOptions={coachMenuOptions}
                onSetTab={props.setTabIndex}
                tabIndex={4}
                selectedTab={props.tabIndex}
                setAnchorEl={setAnchorCoachesEl}
                anchorEl={anchorCoachesEl}
                setOpenMenu={setOpenCoachesMenu}
                openMenu={openCoachesMenu}
                setSelectedIndex={setSelectedCoachesIndex}
                selectedIndex={selectedCoachesIndex}
            />

            <MenuComponent
                id='resources-menu'
                menuOptions={resourceMenuOptions}
                onSetTab={props.setTabIndex}
                tabIndex={5}
                selectedTab={props.tabIndex}
                setAnchorEl={setAnchorResourcesEl}
                anchorEl={anchorResourcesEl}
                setOpenMenu={setOpenResourcesMenu}
                openMenu={openResourcesMenu}
                setSelectedIndex={setSelectedResourcesIndex}
                selectedIndex={selectedResourcesIndex}
            />

            <MenuComponent
                id='about-menu'
                menuOptions={aboutMenuOptions}
                onSetTab={props.setTabIndex}
                tabIndex={6}
                selectedTab={props.tabIndex}
                setAnchorEl={setAnchorAboutEl}
                anchorEl={anchorAboutEl}
                setOpenMenu={setOpenAboutMenu}
                openMenu={openAboutMenu}
                setSelectedIndex={props.setSelectedAboutIndex}
                selectedIndex={props.selectedAboutIndex}
            />
        </React.Fragment>
    );

    const drawer = (
        <React.Fragment>
            <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
                classes={{paper: classes.drawer}}
            >
                <div className={classes.toolbarMargin}/>
                <NavListItem
                    key = {`${Math.floor(Math.random() * 1000)}`}
                    routes={routes}
                    setOpenDrawer={setOpenDrawer}
                    setTabIndex={props.setTabIndex}
                    tabIndex={props.tabIndex}
                    setTabSubIndex={setTabSubIndex}
                    tabSubIndex={tabSubIndex}
                />
            </SwipeableDrawer>
            <IconButton
                className={classes.drawerIconContainer}
                onClick={() => setOpenDrawer(!openDrawer)}
                disableRipple>
                <MenuIcon className={classes.drawerIcon}/>
            </IconButton>
        </React.Fragment>
    );

    return(
        <React.Fragment>
            <ElevationScroll>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar >
                        {matches ? drawer: null}
                        <Button component={Link} to='/' onClick={() => props.setTabIndex(0)} className={classes.logoContainer} >
                            <img src={require('../../assets/logo.png')} alt='EYSC Logo' className={classes.logo} />
                        </Button>
                        {matches ? null: tabs}
                        <LanguageSelector />
                        {/*<Button*/}
                        {/*    variant='contained'*/}
                        {/*    color='secondary'*/}
                        {/*    component={Link}*/}
                        {/*    to="/registration/"*/}
                        {/*    onClick={() =>{*/}
                        {/*        props.setTabIndex(7);*/}
                        {/*    }}*/}
                        {/*    className={classes.button}>{t('navbar.register')} </Button>*/}
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <div className={classes.toolbarMargin} />
        </React.Fragment>
    );
}