import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Trainer } from './Coaches.model';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme:Theme) => ({
    card: {
        width: '300px',
        height: '100%'
    },
    media: {
        height: '19rem',
        backgroundPosition: 'top',
    },
    heading: {
        backgroundColor: theme.palette.secondary.light,
        textAlign:'center'
    },
    link: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
}));

const TrainersCard = (props: Trainer) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const imageName = props.imagePath? props.imagePath : 'coach-placeholder';
    return (
        <React.Fragment>
            <Grid item>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={require (`../../../public/assets/${imageName}.jpg`)}
                            title="Trainer Image"
                        />
                        <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
                            {t(props.type)}
                        </Typography>
                        <CardContent>
                            <Typography variant="h6"  component="span">
                                {props.name}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </React.Fragment>
    );
};

export default TrainersCard;